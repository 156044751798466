/* eslint-disable no-use-before-define */
import React, { useMemo, useState, useEffect } from 'react';
import { Calendar as RNCalendar } from 'react-native-calendars';
import { theme } from 'src/constants/theme';
import {
  format, differenceInDays, addDays, isSameDay,
} from 'date-fns';
import { formatDateToText } from 'src/utils';
import { LeftArrowIcon, RightArrowIcon, CalendarIcon } from 'src/assets/icons';
import { StyleSheet } from 'react-native';
import Common from '../common';

const calendarTheme = {
  calendarBackground: theme.backgroundSecondary,
  textDisabledColor: theme.calendar.disabledDayText,
  dayTextColor: theme.calendar.dayText,
  textDayFontFamily: theme.fonts.regular,
  todayTextColor: theme.secondaryColor,
  textDayFontSize: 16,
  arrowColor: theme.secondaryColor,
  monthTextColor: theme.calendar.dayText,
  textMonthFontFamily: theme.fonts.bold,
  textMonthFontWeight: 'bold',
  textMonthFontSize: 18,
  textSectionTitleColor: theme.calendar.dayText,
  textDayHeaderFontSize: 14,
};

const DateTime = ({ selectedDate, onChangeAnswer }) => {
  const [date, setDate] = useState('');
  const [show, setShow] = useState(false);
  const onChangeDate = ({ dateString }) => {
    setDate(dateString);
    setShow(false);
  };
  useEffect(() => {
    onChangeAnswer({
      dateValue: date,
    });
  }, [date]);
  return (
    <>
      <Common.View>
        <Common.TouchableOpacity style={[styles.input]} onPress={() => setShow(!show)}>
          <Common.Text style={styles.text}>
            {date || 'Select date'}
          </Common.Text>
          <CalendarIcon />
        </Common.TouchableOpacity>
      </Common.View>
      {show && (
        <Common.View style={{
          position: 'absolute',
          top: 20,
          left: 24,
          right: 24,
        }}
        >
          <RNCalendar
            current={new Date()}
          // minDate={new Date()}
        // maxDate={maxDate}
            markedDates={{
              [date]: { selected: true },
            }}
            style={{
              backgroundColor: theme.backgroundSecondary,
              borderRadius: 10,
              padding: 5,
            }}
            renderArrow={(direction) => {
              if (direction === 'left') return <LeftArrowIcon />;
              if (direction === 'right') return <RightArrowIcon />;
              return null;
            }}
            theme={{
              ...calendarTheme,
              selectedDayBackgroundColor: theme.calendar.selectedBackground,
              selectedDayTextColor: theme.calendar.dayText,

            }}
            onDayPress={onChangeDate}
          />
        </Common.View>
      )}
    </>
  );
};

export default DateTime;

const styles = StyleSheet.create({
  input: {
    backgroundColor: theme.input.background,
    borderRadius: 10,
    height: 48,
    paddingHorizontal: 16,
    alignItems: 'center',
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 18,
    letterSpacing: 0.5,
    color: theme.primaryText,
    fontFamily: theme.fonts.regular,
  },
});
