import React, { useLayoutEffect } from 'react';
import Common from 'src/components/common';
import { LocationIcon, RightArrowIcon } from 'src/assets/icons';
import { StyleSheet, RefreshControl } from 'react-native';
import { permiraAPI } from 'src/constants/api';
import queryKeys from 'src/constants/queryKeys';
import { useFetch } from 'src/hooks/reactQuery';

import { locationIcons } from 'src/constants/bookings';
import { useLocation } from 'src/hooks/useLocation';
import navigationService from 'src/utils/navigation';
import screenNames from 'src/constants/screenNames';
import { isDeskTopWeb } from 'src/utils/responsive';

const PickLocation = ({ navigation }) => {
  const {
    data, error, isLoading, isFetching, refetch,
  } = useFetch(queryKeys.GET_LOCATIONS, permiraAPI.getLocations, {
    retry: false,
  });
  const { updateLocation } = useLocation();
  const onSelectLocation = (code) => {
    const canGoBack = navigation?.canGoBack();
    updateLocation(code);
    if (canGoBack) {
      navigationService.goBack();
    } else {
      navigationService.navigate(screenNames.HOME_TABS);
      // navigationService.pop();
      // navigationService.returnHome();
    }
  };

  const locations = data?.locations;
  // useLayoutEffect(() => {
  //   // const canGoBack = navigation?.canGoBack();
  //   // const options = {};
  //   // if (!canGoBack) {
  //   //   options.gestureEnabled = false;
  //   //   options.headerLeft = null;
  //   // }
  //   // navigation.setOptions(options);
  // }, []);
  return (
    <Common.ScrollView
      refreshControl={(
        <RefreshControl
          refreshing={!isLoading && isFetching}
          onRefresh={refetch}
          tintColor="#fff"
        />
      )}
      contentContainerStyle={[styles.scrollviewContainer, isDeskTopWeb() && {
        width: 450,
        alignSelf: 'center',
      }]}
      style={styles.container}
    >
      <Common.Title title="Pick location" />
      {isLoading && (<Common.Spinner />)}
      {locations?.length && locations?.map((location) => (
        <Common.View style={styles.materic} key={location?.region}>
          <Common.Text bold size={24} style={{ zIndex: 1 }}>{location?.region}</Common.Text>
          {locationIcons[location?.region?.toUpperCase()]}
          {
            location?.cities?.map((city) => (
              <Common.TouchableOpacity key={city?.description} onPress={() => onSelectLocation(city?.code)}>
                <Common.FlexView style={styles.countryContainer} flexDirection="row" alignItems="center">
                  <Common.FlexView style={{ flex: 1 }}>
                    <Common.Text style={styles.country} size={16} bold>{city?.description}</Common.Text>
                    <Common.Text style={styles.address}>{city?.address}</Common.Text>
                  </Common.FlexView>
                  <RightArrowIcon />
                </Common.FlexView>
              </Common.TouchableOpacity>
            ))
          }
        </Common.View>
      ))}
    </Common.ScrollView>
  );
};

export default PickLocation;

const styles = StyleSheet.create({
  container: {

  },
  scrollviewContainer: {
    paddingBottom: 45,
    flexGrow: 1,
    paddingHorizontal: 24,
  },
  materic: {
    minHeight: 200,
    marginBottom: 30,
  },
  countryContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#4A495C',
    paddingVertical: 15,
  },
  country: {
    marginBottom: 5,
  },
  address: {
    color: '#8E8894',
    maxWidth: '80%',
  },
  locationContainer: {
    borderWidth: 1,
    borderColor: '#F68C1F',
    padding: 5,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
});
