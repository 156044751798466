import React from 'react';
import { ActivityIndicator } from 'react-native';
import FlexView from './FlexView';

const Spinner = ({ color, size, style }) => (
  <FlexView alignItems="center" justifyContent="center" style={[style && style]}>
    <ActivityIndicator
      color={color}
      size={size}
    />
  </FlexView>
);

Spinner.defaultProps = {
  color: '#fff',
  size: 'large',
};

export default Spinner;
