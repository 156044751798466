import React from 'react';
import { View } from 'react-native';
import { theme } from 'src/constants/theme';

const defaultStyle = {
  borderRadius: 10,
  backgroundColor: theme.backgroundSecondary,
  flexGrow: 1,
  minHeight: 80,
};

const Card = ({ style, children, ...rest }) => (
  <View {...rest} style={[defaultStyle, style]}>
    {children}
  </View>
);

export default Card;
