import React, { useState, useEffect, useMemo } from 'react';
import Common from 'src/components/common';
import { StyleSheet } from 'react-native';
import { theme } from 'src/constants/theme';
import DateTimePicker from '@react-native-community/datetimepicker';
import {
  format, addMinutes, isTomorrow, isToday,
} from 'date-fns';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
  isIOS, isAndroid, getClosesIntervalMinute, getMinMaxTimeDate,
} from 'src/utils';
import Questionnaire from 'src/components/Questionnaire';
import { useLocation } from 'src/hooks/useLocation';
import { useGetQuestionnaire } from 'src/hooks/api';

const styles = StyleSheet.create({
  title: {
    paddingHorizontal: 16 + 24,
    marginTop: 24,
  },
  wrapper: {
    marginVertical: 5,
  },
  input: {
    backgroundColor: theme.input.background,
    borderRadius: 10,
    height: 48,
    paddingHorizontal: 16,
    justifyContent: 'center',
  },
  text: {
    fontSize: 18,
    letterSpacing: 0.5,
    color: theme.primaryText,
    fontFamily: theme.fonts.regular,
  },
  label: {
    marginBottom: 8,
  },
  disabled: {
    opacity: 0.5,
  },
  iosTimePicker: {
    position: 'absolute',
    left: 0,
    right: 0,
    // top: -200,
    borderRadius: 10,
    zIndex: 100,
    backgroundColor: theme.background,
    marginHorizontal: 24,
    shadowOffset: {
      height: -4,
      width: 0,
    },
    shadowColor: 'rgba(0,0,0,0.5)',
    shadowOpacity: 2,
    shadowRadius: 20,
  },
});

const Attendance = ({
  questionnaireState, showTimeSlots, isEditableMode, onSelectAttendanceTime, currentDate,
}) => {
  const [show, setShow] = useState(false);
  const selectedDate = currentDate || new Date();
  const { data, isLoading } = useGetQuestionnaire({
    fetchWhen: isTomorrow(selectedDate) || isToday(selectedDate),
  });

  const [time, setTime] = useState(selectedDate);
  useEffect(() => {
    if (isEditableMode) {
      setTime(selectedDate);
    } else {
      setTime(selectedDate.setHours(new Date().getHours(), getClosesIntervalMinute(), 0, 0));
    }
  }, [currentDate]);
  const onChange = (event, date) => {
    setTime(date);
    if (isAndroid()) {
      setShow(false);
    }
  };

  const onConfirmTime = () => {
    setShow(false);
    onSelectAttendanceTime(time);
  };

  const insets = useSafeAreaInsets();
  const {location} = useLocation();
  const { minDateTime, maxDateTime } = getMinMaxTimeDate(time);
  const isVisible = useMemo(() => Boolean(location === 'LON' ? !!data?.status?.attestationDue : !!data) && questionnaireState?.visible && !isLoading, [questionnaireState, data, isLoading]);

  useEffect(() => {
    if(!isVisible && typeof questionnaireState.callback === 'function'){
      questionnaireState?.callback()
    }
  }, [isVisible, questionnaireState])

  return (
    <>
      {showTimeSlots && (
      <>
        <Common.View style={{ paddingHorizontal: 24, marginTop: 16 }}>
          <Common.View style={[styles.wrapper]}>
            <Common.Text
              style={styles.label}
              bold
              size={18}
            >
              Time
            </Common.Text>
            <Common.TouchableOpacity style={[styles.input]} onPress={() => setShow(true)}>
              <Common.Text style={styles.text}>
                {format(time, 'hh:mm a')}
              </Common.Text>
            </Common.TouchableOpacity>
          </Common.View>
        </Common.View>
        {show && (
        <Common.View style={[
          styles.iosTimePicker,
          {
            top: -insets.bottom,
          }]}
        >
          <DateTimePicker
            testID="dateTimePicker"
            value={time}
            minuteInterval={15}
            mode="time"
            display="spinner"
            minimumDate={minDateTime}
            maximumDate={maxDateTime}
            textColor="#fff"
            onChange={onChange}
          />
          {isIOS() && <Common.Button title="Confirm" onPress={onConfirmTime} />}
        </Common.View>
        )}
      </>
      )}
      <Questionnaire visible={isVisible} data={data} callback={questionnaireState?.callback} close={questionnaireState?.close} />
    </>
  );
};

export default React.memo(Attendance);
