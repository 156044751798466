/*eslint-disable*/
import * as msal from "@azure/msal-browser";

import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import AzureAuth from './wrappers/react-native-azure-auth';

const loginRequest = {
  scopes: [ 'mail.read', 'profile', 'openId']
}

const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/permira.com',
    clientId: '2659719f-ff98-4bf8-88b5-0331a32171da',
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
    // redirectUri: 'https://94949a98dcca.ngrok.io'
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
}

export const useAuth = () => {
  const [msalInstance, setMsalInstance] = useState(new msal.PublicClientApplication(msalConfig))

  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  const [auth, setAuth] = useState(null);

  const getAccounts = useCallback((username) => {
    if(username){
      const account = msalInstance?.getAccountByUsername(username);
      setAccount(account);
      console.log('Exited getACceounts')
      return account;
    }
    const allAvailableAccounts = msalInstance?.getAllAccounts();

    console.log('allAvailableAccounts:', allAvailableAccounts)
    if (allAvailableAccounts === null) {
        console.error("No accounts detected!");
      return
    } 
    const firstUserName = allAvailableAccounts[0]?.username
    if (allAvailableAccounts.length > 1) {
        console.warn("Multiple accounts detected.");
        if(firstUserName){
          setAccount(msalInstance?.getAccountByUsername(firstUserName))
        }
    } else if (allAvailableAccounts.length === 1) {
      if(firstUserName){
        setAccount(msalInstance?.getAccountByUsername(firstUserName))
      }
    }
    return allAvailableAccounts?.[0];
  }, [msalInstance])

  const handleLoginResponse = useCallback(response => {
    console.log('handleLoginResponse:', response)
    if (response !== null) {
      setAuth(response);
      setAccount(response?.account)
    } else {
      getAccounts();
    }
  }, [])

  const acquireTokenSilent = (username) => {
    const account = getAccounts(username)
    console.log('acquireTokenSilent', account)
    if(account){
      loginRequest.account = account
      return msalInstance?.acquireTokenSilent(loginRequest)
        .then((response) => {
        console.log('acquireTokenSilent response:', response)
          return response
        })
        .catch(err => {
          console.log('silent token flow', err);
          return null;
        })
    }
    return null
  }


  const clear = useCallback(() => {
    setAccount(null);
    setAuth(null);
    msalInstance?.browserStorage?.clear()
    return true
  }, [msalInstance])

  useEffect(() => {
    msalInstance?.handleRedirectPromise().then(response => {
      // remove hash from url
        history.pushState("", document.title, window.location.pathname
        + window.location.search);
      return handleLoginResponse(response)
    })
    .catch(err => {
      console.error(err);
      setError(err?.errorMessage)
    });
    window.permira = {
      acquireTokenSilent: acquireTokenSilent,
      logout: clear
    }
  }, [msalInstance]);

  
  
  const acquireToken = async() => {
    const account = getAccounts()

    if(account){
      loginRequest.account = account;
    }
    
    return msalInstance?.acquireTokenSilent(loginRequest).then(response => {
      console.log('acquireTokenSilent', response)
      setAuth(response);
      setAccount(response?.account);
      return response?.accessToken;
    })
    .catch(error => {
        console.warn("silent token acquisition fails. acquiring token using interactive method");
        if (error) {
            // fallback to interaction when silent call fails
            if(account?.username){
              loginRequest.account = msalInstance?.getAccountByUsername(account.username);
            }

            return msalInstance?.acquireTokenRedirect(loginRequest)
                .then(response => {
                  console.log('acquireTokenRedirect', response)
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            console.warn(error);   
        }
    });
  }

  const login = () => {
    return msalInstance?.loginRedirect(loginRequest)
  }
  const logout = async() => {
    console.log('logout:')
    const logoutRequest = {
        account: msalInstance?.getAccountByUsername(account?.username)
    };

    return msalInstance?.logout(logoutRequest);
  }
  

  return {
    isInProgress: msalInstance?.interactionInProgress(),
    authenticated: !!account,
    accountInfo: account,
    login,
    logout,
    acquireTokenSilent: acquireTokenSilent,
    clear,
    error
  }
}

//   get auth(){
//     const self = this;
//     return {
//       acquireTokenSilent(){
//         return self.msalInstance?.acquireTokenSilent(self.loginRequest)
//             .then(response => {
//                 console.log('response:', response)
//                 // get access token from response
//                 // response.accessToken
//                 return  response
//             })
//             .catch(err => {
//               console.log('err:', err)
//               return self.msalInstance?.acquireTokenRedirect(self.loginRequest);
//               if (err instanceof msal.InteractionRequiredAuthError) {
//                 // fallback to interaction when silent call fails
//               }
//               return null
//             })
//       },
//       clearPersistenCache(){
//         const cachedToken = self.msalInstance?.getCachedToken()
//         self.msalInstance?.clearCacheForScope(cachedToken)
//         return true
//       }
//     }
//   }

//   get webAuth(){
//     const self = this;
//     return {
//       authorize(){
//         return self.msalInstance?.acquireTokenSilent(self.loginRequest).then(tokenResponse => {
//           // Do something with the tokenResponse
//       }).catch(error => {
//           // if (error instanceof InteractionRequiredAuthError) {
//               // fallback to interaction when silent call fails
//           return self.msalInstance?.acquireTokenRedirect(self.loginRequest)
//           // }
//       });
//         // return self.msalInstance?.loginRedirect(self.loginRequest)
//         // .then(response => {
//         //     console.log('response:', response)
//         //     // handle response
//         //     return response
//         // })
//         // .catch(err => {
//         //     console.log('err:', err)
//         //     // handle error
//         //     return null
//         // });
//       }
//     }
//   }
// }