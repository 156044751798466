import React, { useState, useEffect } from 'react';

import Common from '../common';
import BouncyCheckbox from '../Checkbox';

const CheckboxChoice = ({ choices, onChangeAnswer }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    onChangeAnswer({
      choices: selected || [],
    });
  }, [selected]);

  const onAdd = (choice) => {
    if (!selected.includes(choice)) {
      setSelected([...selected, choice]);
    }
  };
  const onRemove = (choice) => {
    if (selected.includes(choice)) {
      setSelected(selected.filter((el) => el !== choice));
    }
  };
  return (
    <Common.View style={{ marginTop: 20 }}>
      {choices?.map((choice, idx) => (
        <BouncyCheckbox
          key={choice}
          isChecked={!!selected?.[choice]}
          textColor="#000"
          fillColor="red"
          text={choice}
          onPress={(value) => (value ? onAdd(choice) : onRemove(choice))}
        />
      ))}
    </Common.View>
  );
};

export default CheckboxChoice;
