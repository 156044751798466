import React from 'react';
import { View, StatusBar } from 'react-native';

import { theme } from 'src/constants/theme';
import { Provider } from 'react-redux';
import { store } from 'src/redux/store';
import RootNavigation from 'src/navigation/RootNavigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import SuccessAlert from 'src/components/SuccessAlert';
import { ReactQueryConfigProvider } from 'react-query';
import reactQueryConfig from 'src/config/reactQueryConfig';
// import PickerDemo from 'src/components/Picker2';

const App = () => (
  <Provider store={store}>
    <ReactQueryConfigProvider config={reactQueryConfig}>
      <SafeAreaProvider>
        <View style={{ flexGrow: 1, height: '100%', backgroundColor: theme.background }}>
          <StatusBar backgroundColor={theme.background} barStyle="light-content" />
          <RootNavigation />
          <SuccessAlert />
          {/* <PickerDemo /> */}
        </View>
      </SafeAreaProvider>
    </ReactQueryConfigProvider>
  </Provider>
);

export default App;
