import React from 'react';
import Common from 'src/components/common';
import { logout } from 'src/utils/azureAuth';
import { useSelector } from 'react-redux';
import { isDeskTopWeb } from 'src/utils/responsive';
import { theme } from 'src/constants/theme';
import { StyleSheet } from 'react-native';
import packageJson from '../../../package.json';

const Settings = () => {
  const AuthInfo = useSelector((store) => store.auth);
  return (
    <Common.FlexView style={[{ paddingHorizontal: 24 }, isDeskTopWeb() && {
      width: 450,
      alignSelf: 'center',
    }]}
    >
      <Common.Title title="Settings" />
      <Common.Input
        label="Name"
        value={AuthInfo?.username}
        style={{ marginBottom: 20 }}
        disabled
      />
      <Common.Input
        label="Email"
        value={AuthInfo?.username}
        style={{ marginBottom: 40 }}
        disabled
      />
      <Common.FlexView style={styles.appVersion}>
        <Common.Text color={theme.secondaryText}>
          App version:
          {' '}
          {packageJson.version}
        </Common.Text>
      </Common.FlexView>
      {__DEV__ && <Common.Button style={{ marginTop: 30 }} title="Log out" onPress={logout} />}
    </Common.FlexView>
  );
};

export default Settings;

const styles = StyleSheet.create({
  appVersion: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    padding: 10,
  },
});
