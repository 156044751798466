import React, { useMemo, useEffect } from 'react';
import Common from 'src/components/common';
import { StyleSheet } from 'react-native';

import { keyBy } from 'lodash';
import { formatDateToText } from 'src/utils';
import RadioChoice from 'src/components/RadioChoice';
import PickerEmptyMessage from './PickerEmptyMessage';

const generateSlotItems = (slots) => slots.map((slot) => ({
  label: slot,
  value: slot,
}));

const styles = StyleSheet.create({
  title: {
    paddingHorizontal: 16 + 24,
    marginTop: 24,
  },
});

const BikeBooking = ({
  data, selectedDate, state, currentDateData, onUpdateBookingInformation,
}) => {
  const slotsByDate = useMemo(() => (data?.availability ? keyBy(data.availability, 'bookingDate') : null), [data, selectedDate]);
  const currentDateSlots = slotsByDate?.[formatDateToText(selectedDate || data?.availability?.[0]?.bookingDate)]?.slots;
  const options = data?.options;
  const slots = useMemo(() => {
    if (currentDateData?.slots) {
      return generateSlotItems(currentDateData.slots);
    }
    return [];
  }, [currentDateSlots]);
  const onUpdate = (key, value) => {
    onUpdateBookingInformation({
      ...state.bookingInformation,
      [key]: value,
    });
  };
  useEffect(() => {
    onUpdateBookingInformation({
      Slot: null,
    });
  }, []);
  return (
    <Common.View>
      {options?.includes('Slot') && (
        <>
          <Common.Text bold size={18} style={styles.title}>Slots</Common.Text>
          {slots?.length ? (
            <Common.View style={{ paddingHorizontal: 40 }}>
              <RadioChoice
                choices={slots}
                onChange={(value) => onUpdate('Slot', value)}
              />
            </Common.View>
          ) : (
            <PickerEmptyMessage message="No slots available" />
          )}
        </>
      )}
    </Common.View>
  );
};

BikeBooking.defaultProps = {
  data: [],
};

export default BikeBooking;
