import React, { useMemo } from 'react';
import { Calendar as RNCalendar } from 'react-native-calendars';
import { theme } from 'src/constants/theme';
import { differenceInDays, addDays, isSameDay } from 'date-fns';
import { formatDateToText, getProperDate } from 'src/utils';
import { LeftArrowIcon, RightArrowIcon } from 'src/assets/icons';

const calendarTheme = {
  calendarBackground: theme.backgroundSecondary,
  textDisabledColor: theme.calendar.disabledDayText,
  dayTextColor: theme.calendar.dayText,
  textDayFontFamily: theme.fonts.regular,
  todayTextColor: theme.secondaryColor,
  textDayFontSize: 16,
  arrowColor: theme.secondaryColor,
  monthTextColor: theme.calendar.dayText,
  textMonthFontFamily: theme.fonts.bold,
  textMonthFontWeight: 'bold',
  textMonthFontSize: 18,
  textSectionTitleColor: theme.calendar.dayText,
  textDayHeaderFontSize: 14,
};

const Calendar = ({
  maxDate, isEditableMode, availableDates, selectedDate, onChangeDate,
}) => {
  const availablDatesArr = useMemo(() => (availableDates ? Object.keys(availableDates) : null), [availableDates]);
  const startDate = getProperDate(availablDatesArr?.[0]) || new Date();
  const markedDates = useMemo(() => {
    if (!availablDatesArr?.length) return {};
    const endDate = getProperDate(availablDatesArr?.[availablDatesArr?.length - 1]);
    const diffDays = differenceInDays(endDate, startDate);

    const result = {};
    if (diffDays) {
      [...Array(diffDays + 1).keys()].forEach((i) => {
        const date = formatDateToText(addDays(startDate, i));
        if (!availableDates?.[date]) {
          result[date] = {
            disabled: true, disableTouchEvent: true, marked: true, dotColor: 'red',
          };
        } else {
          result[date] = { textColor: 'white', dotColor: 'green', marked: true };
        }
      });
    }
    const userSelectedDate = selectedDate || startDate;

    if (isSameDay(userSelectedDate, new Date())) {
      result[formatDateToText(userSelectedDate)] = { selected: true };
    } else {
      result[formatDateToText(userSelectedDate)] = { selected: true, marked: true, dotColor: 'green' };
    }

    return result;
  },
  [availablDatesArr, selectedDate]);
  return (
    <RNCalendar
      current={new Date()}
      minDate={startDate}
      maxDate={maxDate}
      style={{
        backgroundColor: theme.backgroundSecondary,
        borderRadius: 10,
        padding: 5,
      }}
      renderArrow={(direction) => {
        if (direction === 'left') return <LeftArrowIcon />;
        if (direction === 'right') return <RightArrowIcon />;
        return null;
      }}
      markedDates={markedDates}
      // markingType="custom"
      theme={{
        ...calendarTheme,
        selectedDayBackgroundColor: theme.calendar.selectedBackground,
        selectedDayTextColor: theme.calendar.dayText,

      }}
      onDayPress={(...args) => {
        if (!isEditableMode) {
          onChangeDate(...args);
        }
      }}
    />
  );
};

export default Calendar;
