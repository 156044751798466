/* eslint-disable no-use-before-define */
import React from 'react';
import { StyleSheet } from 'react-native';
import { responsiveHeight, isDeskTopWeb } from 'src/utils/responsive';
import { responsiveWidth } from 'src/utils';
import { theme } from 'src/constants/theme';
import { LeftArrowIcon } from 'src/assets/icons';
import Common from '../common';
import YesNo from './YesNo';
import Choice from './Choice';
import DateTime from './DateTime';
import CheckboxChoice from './CheckboxChoice';

const CARD_HEIGHT = isDeskTopWeb() ? 500 : responsiveHeight(70);
const CARD_WIDTH = isDeskTopWeb() ? 350 : responsiveWidth(80);

const isCheckBoxType = (question) => question?.type === 'choice' && question?.options?.displayAs === 'checkBoxes';

const isRadioType = (question) => question?.type === 'choice' && question?.options?.displayAs === 'radioButtons';
const isYesNoType = (question) => question?.type === 'boolean';
const isDateType = (question) => question?.type === 'dateTime';

const isAnswered = (answer, item) => {
  console.log('isAnswered:', answer);
  if (isRadioType(item) || isCheckBoxType(item)) {
    return Array.isArray(answer?.choices) && answer.choices.length > 0;
  }
  if (isYesNoType(item)) {
    return answer?.booleanValue !== null && answer?.booleanValue !== undefined;
  }
  if (isDateType(item)) {
    return !!answer?.dateValue;
  }
  return false;
};
const QuestionCard = ({
  onOpenNextCard, isCurrentCard, answer, onOpenPrevCard, isFirstCard, item, onSubmit, onChangeAnswer, isLastCard,
}) => {
  console.log('answer:', answer);
  return (
    <Common.View
      style={[styles.card, !isCurrentCard && {
        opacity: 1,
      }]}
      onStartShouldSetResponder={() => true}
    >
      {!isCurrentCard && (
      <Common.View style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: 10,
        zIndex: 10,
      }}
      />
      )}
      <Common.View style={[styles.header, isFirstCard && {
        paddingHorizontal: 24,
      }]}
      >
        {!isFirstCard && (
        <Common.TouchableOpacity style={styles.goBackBtn} onPress={onOpenPrevCard}>
          <LeftArrowIcon />
        </Common.TouchableOpacity>
        )}
        <Common.Text size={23} bold>{item?.displayName}</Common.Text>
      </Common.View>
      <Common.ScrollView contentContainerStyle={styles.content}>
        <Common.Text size={16}>{item?.description}</Common.Text>
        {isYesNoType(item) && (
        <YesNo onOpenNextCard={onOpenNextCard} onChangeAnswer={onChangeAnswer} />
        )}
        {isRadioType(item) && (
        <Choice onChangeAnswer={onChangeAnswer} choices={item?.options?.choices} />
        )}
        {isCheckBoxType(item) && (
        <CheckboxChoice onChangeAnswer={onChangeAnswer} choices={item?.options?.choices} />
        )}
        {isDateType(item) && (
        <DateTime onChangeAnswer={onChangeAnswer} />
        )}
      </Common.ScrollView>
      {isLastCard ? (
        <Common.Button disabled={!isAnswered(answer, item)} title="Submit" onPress={onSubmit} style={{ borderTopStartRadius: 0, borderTopEndRadius: 0 }} />
      ) : (
        <Common.Button disabled={!isAnswered(answer, item)} title="Continue" onPress={onOpenNextCard} style={{ borderTopStartRadius: 0, borderTopEndRadius: 0 }} />
      )}

    </Common.View>
  );
};
export default QuestionCard;

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#38374A',
    borderRadius: 10,
    height: CARD_HEIGHT,
    width: CARD_WIDTH,
    marginHorizontal: 10,
  },
  header: {
    height: 56,
    // paddingHorizontal: 24,
    paddingVertical: 16,
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    elevation: 3,
    shadowOpacity: 0.5,
    backgroundColor: theme.backgroundSecondary,
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  goBackBtn: {
    height: 30,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    justifyContent: 'center',
    flexGrow: 1,
  },
});
