import { useState } from 'react';

const defaultQuestionnaireState = {
  visible: false,
  callback: () => {},
  close: () => {},
  reset: () => {},
};

export const useQuestionnaire = (defaultState) => {
  const [questionnaireState, setQuestionnaireState] = useState({ ...defaultQuestionnaireState, ...defaultState });
  return {
    ...questionnaireState,
    reset: () => setQuestionnaireState(defaultQuestionnaireState),
    update: (newState) => setQuestionnaireState(newState),
  };
};
