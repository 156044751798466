import { Dimensions, Platform, Linking } from 'react-native';
import { bookingTypes } from 'src/constants/bookings';
import { values } from 'lodash';
import { format } from 'date-fns';

export const SCREEN_WIDTH = Dimensions.get('screen').width;
export const WINDOW_WIDTH = Dimensions.get('window').width;

export function responsiveWidth(percent) {
  const widthPercent = (percent * WINDOW_WIDTH) / 100;
  return Math.round(widthPercent);
}

export const isIOS = () => Platform.OS === 'ios';
export const isWeb = () => Platform.OS === 'web';
export const isAndroid = () => Platform.OS === 'android';

export const isBiking = (bookingType) => bookingType === bookingTypes.BIKE;
export const isShower = (bookingType) => bookingType === bookingTypes.SHOWER;
export const isAttendance = (bookingType) => bookingType === bookingTypes.ATTENDANCE;
export const isLunch = (bookingType) => bookingType === bookingTypes.LUNCH;

export const isValidType = (type) => values(bookingTypes).includes(type);

export const openUrl = (url, params) => {
  if (isWeb()) {
    window.open(url, params);
  } else {
    Linking.openURL(url);
  }
};

export const getProperDate = (date) => {
  if (typeof date !== 'string') {
    return date;
  }
  const [year, month, day] = date.substr(0, 10).split('-');
  return new Date(
    year,
    (month - 1),
    day,
  );
};

export const formatDateToText = (date, strFormat = 'yyyy-MM-dd') => {
  if (!date) return null;
  const parsedDate = date;
  if (typeof date === 'string') {
    return format(getProperDate(date), strFormat);
  }
  return format(parsedDate, strFormat);
};

export const transformBookingToStateFormat = (BookingDate, collectionItem) => {
  const type = collectionItem?.bookingType;
  const payload = {
    BookingType: type,
    BookingDate: formatDateToText(BookingDate),
    bookingInformation: {},
  };
  if (isBiking(type)) {
    payload.bookingInformation = {
      Slot: collectionItem?.slot,
    };
  }
  if (isLunch(type)) {
    payload.bookingInformation = {
      MealType: collectionItem?.mealType,
      Slot: collectionItem?.slot,
    };
  }
  if (isShower(type)) {
    payload.bookingInformation = {
      Cubicle: collectionItem?.cubicle,
      Slot: collectionItem?.slot,
      slots: collectionItem.slots,
    };
  }
  payload.bookingInformation.id = collectionItem?.id;
  return payload;
};

export const getClosesIntervalMinute = () => {
  const currentMinute = new Date().getMinutes();
  return [15, 30, 45, 60].find((interval) => currentMinute <= interval);
};

export const getMinMaxTimeDate = (date) => {
  const minDateTime = new Date(date).setHours(8, 0, 0, 0);
  const maxDateTime = new Date(date).setHours(20, 0, 0, 0);
  return {
    minDateTime,
    maxDateTime,
  };
};
