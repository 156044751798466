import * as msal from "@azure/msal-browser";
import { authProvider, authenticationParameters } from '../utils/azureAuth';



export default class AzureAuth {
  scopes = [ 'mail.read', 'profile','openid']
  msalInstance = null

  loginRequest = {
    scopes: [ 'mail.read', 'profile','openid']
  }

  constructor(config){
    const msalConfig = {
      auth: {
        authority: 'https://login.microsoftonline.com/rfalab.com',
        clientId: '3fb28853-6526-431e-939e-04828c304ab4',
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        // redirectUri: 'https://94949a98dcca.ngrok.io'
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
      }
  };
  
  // Create an instance of PublicClientApplication
  const msalInstance = new msal.PublicClientApplication(msalConfig);
  
 
  // Redirect: once login is successful and redirects with tokens, call Graph API
  // msalInstance.handleRedirectPromise().then((redirectPromiseResponse) => {
  //   console.log('redirectPromiseResponse:', redirectPromiseResponse)

  // }).catch(err => {
  //   console.error(err);
  // });
  
  //   msalInstance.handleRedirectCallback((error, response) => {
  //     console.log('error:', error)
  //     console.log('response:', response)
  //     // handle redirect response or error
  //  });
   this.msalInstance = msalInstance


  //  if(msalInstance.get)
  }

  get auth(){
    const self = this;
    return {
      acquireTokenSilent(){
        return authProvider.getAccessToken(authenticationParameters);
        // return authProvider.acquireTokenSilent(authenticationParameters)
        //     .then(response => {
        //         console.log('response:', response)
        //         // get access token from response
        //         // response.accessToken
        //         return  response
        //     })
        //     .catch(err => {
        //       console.log('err:', err)
        //       return authProvider.acquireTokenRedirect(authenticationParameters);
        //       if (err instanceof msal.InteractionRequiredAuthError) {
        //         // fallback to interaction when silent call fails
        //       }
        //       return null
        //     })
      },
      clearPersistenCache(){
        const cachedToken = authProvider.getCachedToken(authenticationParameters)
        authProvider.clearCacheForScope(cachedToken)
        return true
      }
    }
  }

  // get webAuth(){
  //   const self = this;
  //   return {
  //     authorize(){
  //       return self.msalInstance.acquireTokenSilent(self.loginRequest).then(tokenResponse => {
  //         // Do something with the tokenResponse
  //     }).catch(error => {
  //         // if (error instanceof InteractionRequiredAuthError) {
  //             // fallback to interaction when silent call fails
  //         return self.msalInstance.acquireTokenRedirect(self.loginRequest)
  //         // }
  //     });
  //       // return self.msalInstance.loginRedirect(self.loginRequest)
  //       // .then(response => {
  //       //     console.log('response:', response)
  //       //     // handle response
  //       //     return response
  //       // })
  //       // .catch(err => {
  //       //     console.log('err:', err)
  //       //     // handle error
  //       //     return null
  //       // });
  //     }
  //   }
  // }
}