import queryKeys from 'src/constants/queryKeys';
import { permiraAPI } from 'src/constants/api';

import { useFetch, apiCache, useUpdate } from './reactQuery';
import { useLocation } from './useLocation';

export const useGetResource = (bookingType) => {
  const { location } = useLocation();
  return useFetch(Boolean(bookingType && location) && [queryKeys.RESOURCES, { bookingType, location }], permiraAPI.getResourcesByType);
};

export const usePrefetchResource = () => {
  const { location } = useLocation();
  return (bookingType) => apiCache.prefetchQuery(
    Boolean(bookingType && location)
      && [queryKeys.RESOURCES, { bookingType, location }], permiraAPI.getResourcesByType,
  );
};

export const useGetMyBookings = () => {
  const { location } = useLocation();
  return useFetch(location && [queryKeys.MY_BOOKINGS, { location }], permiraAPI.getMyBookings, {
    refetchInterval: 30000, // 30 sec,
  });
};

const questionnaireLocations = ['LON'];

export const useGetQuestionnaire = ({ fetchWhen = true }) => {
  const { location } = useLocation();
  const isValidLocation = location && !!questionnaireLocations?.[location];
  return useFetch(
    (fetchWhen && isValidLocation)
      && queryKeys.QUESTIONNAIRE, () => permiraAPI.getQuestionnaire(location),
  );
};

export const useCreateBooking = () => {
  const { location } = useLocation();
  const [mutate] = useUpdate(permiraAPI.createBooking, {
    onSuccess: (response, variables) => {
      const BookingType = variables?.BookingType;
      apiCache.invalidateQueries([queryKeys.MY_BOOKINGS, { location }]);
      apiCache.invalidateQueries([queryKeys.RESOURCES, { bookingType: BookingType, location }]);
    },
  });
  return mutate;
};

export const useCreateMultipleBooking = () => {
  const { location } = useLocation();
  const [mutate] = useUpdate(permiraAPI.createBookingMultipe, {
    onSuccess: (response, variables) => {
      const BookingType = variables?.BookingType;
      apiCache.invalidateQueries([queryKeys.MY_BOOKINGS, { location }]);
      apiCache.invalidateQueries([queryKeys.RESOURCES, { bookingType: BookingType, location }]);
    },
  });
  return mutate;
};

export const useDeleteBooking = () => {
  const [mutate] = useUpdate(permiraAPI.deleteBooking, {
    onSuccess: () => {
      apiCache.invalidateQueries(queryKeys.MY_BOOKINGS);
      apiCache.invalidateQueries(queryKeys.RESOURCES);
    },
  });
  return mutate;
};

export const useGetBookingTypes = () => {
  const { location } = useLocation();
  return useFetch(
    location && [queryKeys.GET_BOOKING_TYPES, { location }], permiraAPI.getBookingTypes,
  );
};
