const screenNames = {
  BOOKINGS: 'Bookings',
  LOGIN: 'Login',
  SETTINGS: 'Settings',
  SINGLE_BOOKING: 'Booking',
  HOME_TABS: 'Home',
  BOOKING_PREVIEW: 'Booking preview',
  PICK_LOCATION: 'Pick location',
  QUESTIONNAIRE: 'Questionnaire',
};

export default screenNames;
