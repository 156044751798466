import actionTypes from 'src/constants/actionTypes';

export const showSuccessAlertAction = (payload) => ({
  type: actionTypes.SHOW_SUCCESS_ALERT,
  payload,
});

export const hideSuccessAlertAction = () => ({
  type: actionTypes.HIDE_SUCCESS_ALERT,
});

export const resetSuccessAlertAction = () => ({
  type: actionTypes.RESET_SUCCESS_ALERT,
});
