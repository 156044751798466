/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';

import { StyleSheet, FlatList } from 'react-native';
import { responsiveWidth } from 'src/utils';
import { isDeskTopWeb, responsiveHeight } from 'src/utils/responsive';
import { permiraAPI } from 'src/constants/api';
import { useLocation } from 'src/hooks/useLocation';
import MaterialCommunityIcons from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import Common from '../common';
import QuestionCard from './QuestionCard';
import SuccessAlert from '../SuccessAlert';

const CARD_HEIGHT = isDeskTopWeb() ? 600 : responsiveHeight(80);
const CARD_WIDTH = isDeskTopWeb() ? 350 : responsiveWidth(80);

const Questionnaire = ({
  data, close, visible, callback, bookingId,
}) => {
  const [answers, setAnswers] = useState([]);
  const { location } = useLocation();
  const listRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollToIndex = (index) => {
    if (listRef.current) {
        listRef.current?.scrollToOffset({
          offset: (CARD_WIDTH + 20) * index,
        });
    }
  };

  useEffect(() => {
    if (!visible && data) {
      setAnswers([]);
      setCurrentIndex(0);
    }
  }, [visible]);

  const onChangeAnswer = (index, payload) => {
    const answersClone = [...answers];
    answersClone[index] = payload;
    setAnswers(answersClone);
  };

  const onOpenNextCard = (index) => {
    scrollToIndex(index + 1);
    setCurrentIndex(index + 1);
  };
  const onOpenPrevCard = (index) => {
    scrollToIndex(index - 1);
    setCurrentIndex(index - 1);
  };

  const onSubmit = async () => {
    try {
      SuccessAlert.show('Saving...');
      const response = await permiraAPI.saveQuestionnaire({
        answers: [...answers, {
          name: 'BookingRef',
          type: 'text',
          textValue: bookingId || '',
        }],
      }, location);
      if (response) {
        SuccessAlert.show('Successfully saved!');
        const preventBooking = location === 'LON' ? answers.some((el) => el.booleanValue === false) : answers.some((el) => el.booleanValue === true);
        if (preventBooking) {
          setTimeout(() => {
            SuccessAlert.show(location === 'LON' ? 'Please contact rebecca.west@Permira.com as soon as possible' : 'You are not able to continue your booking at this point. Contact your office manager if you have any further questions.', 'warning');
            if (typeof close === 'function') {
              close();
            }
          }, 2000);
        } else if (typeof callback === 'function') {
          setTimeout(() => {
            SuccessAlert.hide();
            callback(response?.data?.questionnaireId);
          }, 2000);
        }
      } else {
        SuccessAlert.show('Failed. Please contact our support!', 'warning');
      }
    } catch (error) {
      SuccessAlert.show('Failed. Please contact our support!', 'warning');
      if (typeof close === 'function') {
        close();
      }
    }
  };

  if (!visible) return null;

  return (
    <Common.Modal
      visible={visible}
      animationType="fade"
      transparent
    >
      <Common.TouchableOpacity
        style={styles.dimContainer}
        activeOpacity={1}
        // onPress={cancelHandle}
      >
        <Common.View
          style={styles.contentWrapperWrapper}
          activeOpacity={1}
        >
          <Common.View style={{ flexGrow: 1, justifyContent: isDeskTopWeb() ? 'center' : 'flex-end' }}>
            <Common.View style={[isDeskTopWeb() && {
              width: 500,
              alignSelf: 'center',
              justifyContent: 'center',
            }]}
            >
              <Common.TouchableOpacity onPress={close} style={{ alignSelf: 'flex-end', marginRight: 10 }}>
                <MaterialCommunityIcons name="window-close" color="#FFF" size={40} />
              </Common.TouchableOpacity>
              <FlatList
                ref={listRef}
                data={data?.questions || []}
                horizontal
              // initialScrollIndex={index}
                scrollEnabled={false}
                pagingEnabled
                scrollEventThrottle={16}
                decelerationRate="fast"
                snapToInterval={responsiveWidth(80) + 20}
                snapToAlignment="start"
              // onMomentumScrollEnd={onMomentumScrollEnd}
              // scrollEnabled={false}
                showsHorizontalScrollIndicator={false}
                style={[styles.list]}
                extraData={{
                  answers,
                }}
                contentContainerStyle={styles.listContainer}
                renderItem={({ item, index }) => (
                  <QuestionCard
                    isFirstCard={index === 0}
                    isCurrentCard={currentIndex === index}
                    isLastCard={index === data?.questions?.length - 1}
                    onChangeAnswer={(payload) => onChangeAnswer(index, {
                      name: item?.name,
                      type: item?.type,
                      ...payload,
                    })}
                    onOpenNextCard={() => onOpenNextCard(index)}
                    onOpenPrevCard={() => onOpenPrevCard(index)}
                    item={item}
                    onSubmit={onSubmit}
                    answer={answers?.[index]}
                  />
                )}
                keyExtractor={(item) => item?.name}
              />
            </Common.View>
          </Common.View>
        </Common.View>
      </Common.TouchableOpacity>
      <SuccessAlert />
    </Common.Modal>
  );
};

// SuccessAlert.show = (message) => {
//   store?.dispatch(showSuccessAlertAction(message));
// };

// SuccessAlert.hide = () => {
//   store?.dispatch(hideSuccessAlertAction());
// };

export default Questionnaire;

const styles = StyleSheet.create({
  dimContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(19,18,43,0.8)',
    margin: 0,
    padding: 0,
    height: '100%',
  },
  contentWrapperWrapper: {
    width: '100%',
    flexGrow: 1,
  },
  list: {
    flexGrow: 1,
    marginTop: 20,
    maxHeight: CARD_HEIGHT,
  },
  listContainer: {
    flexGrow: 1,
    alignItems: 'flex-end',
    paddingBottom: 60,
  },
});
