import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist';
// import thunk from 'redux-thunk';
// import promise from 'redux-promise-middleware';
import AsyncStorage from '@react-native-community/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { isWeb } from 'src/utils';
import rootReducer from './rootReducer';

let ReactotronConfig;

// if (!isWeb()) {
//   ReactotronConfig = require('../../ReactotronConfig').default;
// }

const persistConfig = {
  key: 'pg_root',
  storage: AsyncStorage,
  timeout: null,
  whitelist: ['auth', 'settings'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const resetReduxPersist = () => purgeStoredState(persistConfig);

// eslint-disable-next-line import/no-mutable-exports
export let reduxPersistor = null;

// eslint-disable-next-line no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = [];
const middlewares = [];
const middlewareEnhancer = applyMiddleware(...middlewares);
if (ReactotronConfig) {
  enhancers.push(ReactotronConfig.createEnhancer());
}
enhancers.push(middlewareEnhancer);
export const store = createStore(persistedReducer, composeEnhancers(...enhancers));
const persistor = persistStore(store);
reduxPersistor = persistor;
