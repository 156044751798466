import React from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import { theme } from 'src/constants/theme';
import PropTypes from 'prop-types';
import Text from './Text';

const styles = StyleSheet.create({
  wrapper: {
    marginVertical: 5,
  },
  input: {
    backgroundColor: theme.input.background,
    borderRadius: 10,
    height: 48,
    paddingHorizontal: 16,
    fontSize: 18,
    letterSpacing: 0.5,
    color: theme.primaryText,
    fontFamily: theme.fonts.regular,
  },
  label: {
    marginBottom: 8,
  },
  disabled: {
    opacity: 0.5,
  },
});

const Input = ({
  label, style, inputStyle, disabled, ...rest
}) => (
  <View style={[styles.wrapper, style && style]}>
    {label && (
    <Text
      style={styles.label}
      bold
      size={18}
    >
      {label}
    </Text>
    )}
    <TextInput
      editable={!disabled}
      style={[styles.input, disabled && styles.disabled, inputStyle && inputStyle]}
      placeholderTextColor={theme.input.placeholder}
      {...rest}
    />
  </View>
);

Input.defaultProps = {
  disabled: false,
  label: null,
  inputStyle: null,
  style: null,
};

Input.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  inputStyle: PropTypes.any,
  style: PropTypes.any,
};

export default Input;
