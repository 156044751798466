import React from 'react';
import { Text as RNText, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { colors } from 'src/constants/theme';

const styles = StyleSheet.create({
  text: {
    color: colors.white,
    paddingVertical: 1,
    fontFamily: 'Calibri',
  },
});

const Text = ({
  children, light, textTransform, bold, size, color, style, ...props
}) => (
  <RNText
    style={[
      styles.text,
      size && { fontSize: size },
      color && { color },
      bold && { fontFamily: 'Calibri-Bold' },
      light && { fontFamily: 'Calibri-Light' },
      textTransform && textTransform !== 'none' && { textTransform },
      style && style,
    ]}
    {...props}
  >
    {children}
  </RNText>
);

Text.defaultProps = {
  size: 14,
  bold: false,
  light: false,
  textTransform: 'none',
  style: null,
  color: null,
  children: '',
};

Text.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.any,
  bold: PropTypes.bool,
  light: PropTypes.bool,
  textTransform: PropTypes.string,
  children: PropTypes.node,
};

export default Text;
