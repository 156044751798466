
/*eslint-disable*/
import React, {useEffect} from 'react';
import { AzureAD,AuthenticationState } from 'react-aad-msal';
import { authProvider, authenticationParameters } from './utils/azureAuth';
import Login from './Login';
// import App from 'src/App';
import {ReactComponent as Logo} from '../public/logo.svg'
import BookingsList from 'src/screens/Bookings/components/BookingsList';
import { myBookings } from './mock-data';
// import Calendar from 'src/components/Calendar';
import Home from './Home2';
import { useAuth } from './useAuth';
import useTimer from 'src/hooks/useTimer';

const App = () => {
  const { accountInfo, acquireTokenSilent,  clear, error, login, authenticated, isInProgress  } = useAuth()
  console.log('authenticated:', authenticated)
  useEffect(() => {
      if (window.location.pathname === '/admin') {
        const ADMIN_URL = 'https://apps.powerapps.com/play/43c4183d-2be5-4c87-b7b2-9fb00f8434a0?tenantId=9b1a952d-a735-47ee-84a8-0b3a8650b8cc'
        window.open(ADMIN_URL, '_self');
      }
    if(!authenticated) {
      window?.closePreloader()
    }
    acquireTokenSilent();
  }, [])
  return (
    <div id="web_container" style={{ backgroundColor: '#100f27'  }}>
    {authenticated && (
      <Home account={accountInfo} />
    )}
    {!authenticated && <Login onLogin={login} isAuthenticating={isInProgress} />}
  </div>
  )
};

export default App