import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Common from 'src/components/common';
import PropTypes from 'prop-types';
import SafeAreaView from 'react-native-safe-area-view';
import { theme } from 'src/constants/theme';
import { isWeb } from 'src/utils';

const styles = StyleSheet.create({
  tabsWrapper: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-around',
    borderTopColor: theme.secondaryColor,
    borderTopWidth: 1,
    alignItems: 'center',
    height: '100%',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingHorizontal: 20,
  },
});

function Tabbar({ state, descriptors, navigation }) {
  const focusedOptions = descriptors[state.routes[state.index].key].options;
  if (focusedOptions.tabBarVisible === false) {
    return null;
  }
  return (
    <SafeAreaView testID="tabbar-safeareaview" forceInset={{ bottom: isWeb() ? 'never' : 'always' }} style={{ backgroundColor: theme.background, height: 49 }}>
      <Common.View style={styles.tabsWrapper}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          // eslint-disable-next-line no-nested-ternary
          const label = options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };

          return (
            <TouchableOpacity
              key={route.key}
              accessibilityRole="button"
              accessibilityStates={isFocused ? ['selected'] : []}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              activeOpacity={0.8}
              style={styles.button}
            >
              <Common.Text light size={18} color={isFocused ? theme.tabActive : theme.tabInactive}>
                {label}
              </Common.Text>
            </TouchableOpacity>
          );
        })}
      </Common.View>
    </SafeAreaView>
  );
}

Tabbar.propTypes = {
  state: PropTypes.any.isRequired,
  navigation: PropTypes.any.isRequired,
  descriptors: PropTypes.any.isRequired,
};

export default Tabbar;
