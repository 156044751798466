import React from 'react'

const Login = ({onLogin, isAuthenticating}) => {
  return (
    <div className="login">
      <button className={`login-button ${isAuthenticating ? 'loading' :''}`} onClick={!isAuthenticating && onLogin}>{isAuthenticating ? 'Authenticating...' : 'Login'}</button>
    </div>
  )
}

export default Login
