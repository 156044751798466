import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { theme } from 'src/constants/theme';
import Text from './Text';

const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    backgroundColor: theme.secondaryColor,
    flexGrow: 1,
    height: 48,
    maxHeight: 48,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    margin: 0,
  },
  title: {
    color: theme.primaryText,
  },
  buttonOutline: {
    backgroundColor: 'transparent',
  },
  buttonDisabled: {
    opacity: 0.6,
  },
});

const Button = ({
  title,
  onPress,
  style,
  disabled,
  opacity,
  type,
  textStyle,
  ...rest
}) => (
  <TouchableOpacity
    disabled={disabled}
    activeOpacity={opacity}
    style={[
      styles.button,
      type === 'outline' && styles.buttonOutline,
      disabled && styles.buttonDisabled,
      style && style,
    ]}
    onPress={onPress}
    {...rest}
  >
    <Text
      style={[
        styles.title,
        textStyle && textStyle,
      ]}
      size={18}
      bold={type === 'primary'}
    >
      {title}
    </Text>
  </TouchableOpacity>
);

Button.propTypes = {
  opacity: PropTypes.number,
  disabled: PropTypes.bool,
  textStyle: PropTypes.object,
  style: PropTypes.object,
  onPress: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'outline']),
};

Button.defaultProps = {
  opacity: 0.7,
  textStyle: null,
  disabled: false,
  style: null,
  type: 'primary',
  onPress: () => {},
};

export default Button;
