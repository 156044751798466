import React, { useLayoutEffect, useEffect } from 'react';
import Common from 'src/components/common';

import { useGetMyBookings, useGetQuestionnaire, useDeleteBooking } from 'src/hooks/api';
import { RefreshControl, StyleSheet } from 'react-native';
import { LocationIcon } from 'src/assets/icons';
import { useQuestionnaire } from 'src/hooks/useQuestionnaire';
import Questionnaire from 'src/components/Questionnaire';
import navigationService from 'src/utils/navigation';
import screenNames from 'src/constants/screenNames';
import { useLocation } from 'src/hooks/useLocation';
import BookingsList from './components/BookingsList';
import NewBookingsList from './components/NewBookingsList';

const styles = StyleSheet.create({
  locationContainer: {
    borderWidth: 1,
    borderColor: '#F68C1F',
    padding: 5,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  marginLeft: {
    marginLeft: 24,
  },
});

const Bookings = ({ navigation, route }) => {
  const showQuestionnaire = route?.params?.form === 'questionnaire';
  const bookingId = route?.params?.bookingId;
  const bookingType = route?.params?.bookingType;
  const deleteBooking = useDeleteBooking();
  const { location } = useLocation();
  const {
    visible, reset, close, callback,
  } = useQuestionnaire({
    visible: !!showQuestionnaire,
    close: () => {
      if (bookingType && bookingId) {
        deleteBooking({ bookingType, bookingId }).catch((err) => {
          console.log('booking deletion failed');
        });
      }
      reset();
      navigation?.reset({
        index: 0,
        routes: [{ name: screenNames.HOME_TABS }],
      });
    },
    callback: () => {
      console.log('callback called');
      reset();
      navigation?.reset({
        index: 0,
        routes: [{ name: screenNames.HOME_TABS }],
      });
    },
  });
  const {
    data, isLoading, isFetching, refetch,
  } = useGetMyBookings();

  const {
    data: questionnaireData,
    isLoading: questionnaireLoading,
  } = useGetQuestionnaire({
    fetchWhen: !!showQuestionnaire,
  });
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: null,
      gestureEnabled: false,
      headerRight: () => (
        <Common.View style={styles.locationContainer}>
          <LocationIcon />
        </Common.View>
      ),
    });
  }, []);

  return (
    <>
      <Common.FlatList
        contentContainerStyle={{ flexGrow: 1, paddingBottom: 45 }}
        refreshControl={(
          <RefreshControl
            refreshing={!isLoading && isFetching}
            onRefresh={refetch}
            tintColor="#fff"
          />
      )}
        ListHeaderComponent={(
          <>
            <Common.Title style={styles.marginLeft} title="Your Bookings" />
            <BookingsList data={data} isLoading={isLoading} />
          </>
      )}
        ListFooterComponent={(
          <>
            <Common.Title style={styles.marginLeft} title="New Booking" />
            <NewBookingsList myBookings={data} isLoading={isLoading} />
          </>
      )}
      />
      <Questionnaire
        bookingId={bookingId}
        visible={visible && !questionnaireLoading && (location === 'LON' ? !!questionnaireData?.status?.attestationDue : !!questionnaireData)}
        data={questionnaireData}
        callback={callback}
        close={close}
      />
    </>
  );
};

export default Bookings;
