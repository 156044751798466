import React, { useState, useEffect } from 'react';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';
import { theme } from 'src/constants/theme';
import PropTypes from 'prop-types';
import Common from './common';

const RadioChoice = ({
  selectBy, selectedSlots, choices, onChange, renderLabel, allowMultiple,
}) => {
  const [selected, setSelected] = useState(selectedSlots || choices?.[0]);

  const onUpdate = (obj) => {
    if (!obj) return;
    if (allowMultiple) {
      onChange(obj.value);
    } else {
      setSelected(obj);
    }
  };

  useEffect(() => {
    if (allowMultiple) {
      setSelected(selectedSlots);
    }
  }, [selectedSlots]);

  useEffect(() => {
    if (!allowMultiple) {
      onChange(selected?.value);
    }
  }, [selected]);

  useEffect(() => {
    onUpdate(choices?.[0]);
  }, [choices]);

  if (!choices?.length) return null;

  return (
    <Common.View style={{ marginTop: 20 }}>
      <RadioForm
        animation
        style={{ flexDirection: 'column' }}
      >
        {
    choices?.map((obj, i) => (
      <RadioButton
        style={{
          marginRight: 30,
          marginBottom: 10,
        }}
        labelHorizontal
        key={i}
      >
        <RadioButtonInput
          obj={obj}
          index={i}
          isSelected={allowMultiple ? selected?.includes(obj?.[selectBy]) : selected?.[selectBy] === obj?.[selectBy]}
          borderWidth={1}
          buttonInnerColor={theme.secondaryColor}
          buttonOuterColor="#6E6C8B"
          buttonSize={15}
          onPress={() => onUpdate(obj)}
          buttonOuterSize={24}
          buttonStyle={{ borderWidth: 2 }}
        />
        {typeof renderLabel === 'function' ? renderLabel(obj, {
          onPress: () => onUpdate(obj),
        }) : (
          <RadioButtonLabel
            obj={obj}
            index={i}
            labelHorizontal
            labelStyle={{ fontSize: 16, color: '#fff' }}
            labelWrapStyle={{}}
            onPress={() => onUpdate(obj)}
          />
        )}
      </RadioButton>
    ))
  }
      </RadioForm>
    </Common.View>
  );
};

RadioChoice.defaultProps = {
  selectBy: 'value',
  renderLabel: null,
  // value: null,
  // selectFirstDefault: false,
  allowMultiple: false,
};

RadioChoice.propTypes = {
  selectBy: PropTypes.oneOf(['value', 'label']),
  renderLabel: PropTypes.func,
  // value: PropTypes.string,
  // selectFirstDefault: PropTypes.bool,
  allowMultiple: PropTypes.bool,
};

export default RadioChoice;
