import React, { useState, useMemo, useEffect } from 'react';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';
import { theme } from 'src/constants/theme';
import Common from '../common';

const Choice = ({ choices, onChangeAnswer, defaultChoice }) => {
  const radioProps = useMemo(() => {
    if (!choices?.length) return [];
    if (typeof choices?.[0] === 'string') {
      return choices?.map((choice) => ({
        label: choice,
        value: choice,
      }));
    }
    if (choices?.[0]?.value && choices?.[0]?.label) {
      return choices;
    }
    throw Error('Choice should be array of strings or array of objects that have value and label properties');
  }, [choices]);
  console.log('radioProps:', radioProps);
  const [selected, setSelected] = useState(defaultChoice || '');
  useEffect(() => {
    onChangeAnswer({
      choices: selected ? [selected] : [],
    });
  }, [selected]);
  useEffect(() => {
    setSelected(defaultChoice || '');
  }, [choices]);
  return (
    <Common.View style={{ marginTop: 20 }}>
      <RadioForm
        // formHorizontal
        animation
        style={{ flexDirection: 'column' }}
      >
        {
    radioProps.map((obj, i) => (
      <RadioButton
        style={{
          marginRight: 30,
          marginBottom: 10,
        }}
        labelHorizontal
        key={i}
        // onPress={() => {}}
      >
        <RadioButtonInput
          obj={obj}
          index={i}
          isSelected={selected === obj.value}
          borderWidth={1}
          buttonInnerColor={theme.secondaryColor}
          buttonOuterColor="#6E6C8B"
          buttonSize={15}
          onPress={() => setSelected(obj?.value)}
          buttonOuterSize={24}
          buttonStyle={{ borderWidth: 2 }}
        />
        <RadioButtonLabel
          obj={obj}
          index={i}
          labelHorizontal
          labelStyle={{ fontSize: 16, color: '#fff' }}
          labelWrapStyle={{}}
          onPress={() => setSelected(obj?.value)}
        />
      </RadioButton>
    ))
  }
      </RadioForm>
    </Common.View>
  );
};

export default Choice;
