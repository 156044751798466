import React, { useState } from 'react';
import Common from 'src/components/common';
import { theme } from 'src/constants/theme';
import { Logo } from 'src/assets/icons';
import { StyleSheet } from 'react-native';
import { login } from 'src/utils/azureAuth';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: theme.background,
    paddingTop: '60%',
  },
  button: {
    position: 'absolute',
    bottom: '15%',
    width: '80%',
  },
});

const Login = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const onLogin = () => {
    setIsAuthenticating(true);
    console.log('CALLED');
    login().finally(() => {
      setIsAuthenticating(false);
    });
  };
  return (
    <Common.FlexView flexDirection="column" alignItems="center" style={styles.wrapper}>
      <Common.View>
        <Logo height="75" />
      </Common.View>
      <Common.Button style={styles.button} title={isAuthenticating ? 'Authenticating...' : 'Log in'} onPress={onLogin} disabled={isAuthenticating} />
    </Common.FlexView>
  );
};

export default Login;
