import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'src/constants/theme';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
  },
});

const FlexView = ({
  children, style, flexDirection, justifyContent, alignItems, backgroundColor, ...rest
}) => (
  <View
    style={[
      styles.wrapper,
      style && style,
      flexDirection && { flexDirection },
      justifyContent && { justifyContent },
      alignItems && { alignItems },
      backgroundColor && { backgroundColor },
    ]}
    {...rest}
  >
    {children}
  </View>
);

FlexView.propTypes = {
  children: PropTypes.node.isRequired,
  flexDirection: PropTypes.oneOf(['column', 'row', 'row-reverse', 'column-reverse']),
  alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  backgroundColor: PropTypes.string,
};

FlexView.defaultProps = {
  flexDirection: null,
  alignItems: null,
  justifyContent: null,
  backgroundColor: null,
};

export default FlexView;
