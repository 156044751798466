import React, { useState, useEffect, useMemo } from 'react';
import Common from 'src/components/common';
import { StyleSheet } from 'react-native';
import RadioChoice from 'src/components/RadioChoice';
import MaterialCommunityIcons from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { colors } from 'src/constants/theme';

const styles = StyleSheet.create({
  title: {
    paddingHorizontal: 16 + 24,
    marginTop: 24,
  },
});

const Shower = ({
  state, currentDateData, onUpdateBookingInformation, onSelectShowerCubicle,
}) => {
  console.log('state:', state);
  const cubicles = useMemo(() => {
    if (!currentDateData?.cubicles) {
      return [];
    }
    return currentDateData?.cubicles?.map((cubicle, idx) => ({
      label: cubicle?.cubicle?.name,
      value: cubicle?.cubicle?.name,
      gender: cubicle?.cubicle?.gender,
    }));
  }, [currentDateData]);
  const [currentCubicle, setCurrentCubicle] = useState(null);

  useEffect(() => {
    const Cubicle = state?.bookingInformation?.Cubicle;
    const cubicleIndex = currentDateData?.cubicles?.findIndex((el) => el?.cubicle?.name === Cubicle);
    const foundCubicleIndex = cubicleIndex > 0 ? cubicleIndex : 0;
    setCurrentCubicle(currentDateData?.cubicles?.[foundCubicleIndex]);
  }, [state, currentDateData]);

  const slots = useMemo(() => {
    if (!currentCubicle?.slots) return [];
    return currentCubicle?.slots?.map((slot) => ({ value: slot, label: slot }));
  }, [currentCubicle]);

  const onUpdateSlots = (value) => {
    if (!value) return;
    let slotsClone = state?.bookingInformation?.Slots || [];
    if (slotsClone.includes(value)) {
      slotsClone = slotsClone.filter((el) => el !== value);
    } else {
      slotsClone.push(value);
    }
    onUpdateBookingInformation({
      Slots: slotsClone,
    });
  };

  const onUpdate = (key, value) => {
    onUpdateBookingInformation({
      [key]: value,
    });
  };
  useEffect(() => {
    onUpdateBookingInformation({
      Slots: currentDateData?.cubicles?.[0]?.cubicle?.slots?.[0] ? [currentDateData?.cubicles?.[0]?.cubicle?.slots?.[0]] : [],
      Cubicle: currentDateData?.cubicles?.[0]?.cubicle?.name,
    });
  }, [currentDateData]);

  useEffect(() => {
    onUpdate('Slots', slots?.[0]?.value ? [slots[0].value] : []);
  }, [currentCubicle]);

  return (
    <Common.View>
      <Common.Text bold size={18} style={styles.title}>Cubicle</Common.Text>
      <Common.View style={{ paddingHorizontal: 40 }}>
        <RadioChoice
          choices={cubicles}
          selectFirstDefault
          onChange={(value) => onUpdate('Cubicle', value)}
          renderLabel={(obj, { onPress }) => (
            <Common.TouchableOpacity
              activeOpacity={0.5}
              onPress={onPress}
              style={{ marginLeft: 10 }}
            >
              <Common.FlexView flexDirection="row" alignItems="center">
                <Common.Text size={16} style={{ marginRight: 10 }}>{obj?.label}</Common.Text>
                {obj?.gender === 'Male' && <MaterialCommunityIcons size={25} name="human-male" color={colors.blue} />}
                {obj?.gender === 'Female' && <MaterialCommunityIcons size={25} name="human-female" color="#eb4559" />}
                {obj?.gender === 'Unisex' && (
                  <Common.FlexView flexDirection="row">
                    <MaterialCommunityIcons size={25} name="human-male" color={colors.blue} />
                    <MaterialCommunityIcons style={{ position: 'absolute', left: 12 }} size={25} name="human-female" color="#eb4559" />
                  </Common.FlexView>
                )}
              </Common.FlexView>
            </Common.TouchableOpacity>
          )}
        />
      </Common.View>
      <Common.Text bold size={18} style={styles.title}>Slots</Common.Text>
      <Common.View style={{ paddingHorizontal: 40 }}>
        <RadioChoice
          choices={slots}
          selectFirstDefault
          allowMultiple
          selectedSlots={state.bookingInformation.Slots}
          onChange={(value) => onUpdateSlots(value)}
        />
      </Common.View>
    </Common.View>
  );
};
export default Shower;
