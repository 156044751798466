/* eslint-disable no-unused-expressions */
import { StackActions, CommonActions } from '@react-navigation/native';
import { createRef } from 'react';
import screenNames from 'src/constants/screenNames';

export const navigationRef = createRef();

const navigateWithReplace = (routeName, params = {}) => {
  navigationRef.current?.dispatch(
    StackActions.replace(routeName, params),
  );
};

const navigate = (routeName, params = {}) => {
  navigationRef.current?.navigate(routeName, params);
};

const reset = (routeName) => {
  navigationRef.current?.dispatch(CommonActions.reset({ routeName }));
};

const goBack = (arg) => {
  if (navigationRef.current?.canGoBack()) {
    navigationRef.current?.dispatch(CommonActions.goBack(arg));
  } else {
    // reset(screenNames.HOME_TABS);
    navigate(screenNames.HOME_TABS);
  }
};

const returnHome = () => {
  navigationRef.current?.dispatch(StackActions.popToTop());
};

const goBackXTimes = (times = 1) => {
  navigationRef.current?.dispatch(StackActions.pop(times));
};

const navigationService = {
  navigate,
  navigateWithReplace,
  goBack,
  reset,
  goBackXTimes,
  returnHome,
};

export default navigationService;
