import actionTypes from 'src/constants/actionTypes';

const defaultState = {
  message: null,
  messageType: 'text',
  visible: false,
  type: 'success',
  disableOverlayClose: false,
  button: {
    visible: false,
    title: 'Ok',
    handler: () => {},
  },
};

const successAlertReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_SUCCESS_ALERT:
      return { visible: true, ...action.payload };
    case actionTypes.HIDE_SUCCESS_ALERT:
      return { ...state, visible: false };
    case actionTypes.RESET_SUCCESS_ALERT:
      return defaultState;
    default:
      return state;
  }
};

export default successAlertReducer;
