import { useEffect, useState, useRef } from 'react';

const useTimer = (seconds) => {
  const [timer, setTimer] = useState(seconds);
  const intervalRef = useRef();
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    if (timer === 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [timer]);
  return {
    timer,
    isFinished: timer === 0,
  };
};

export default useTimer;
