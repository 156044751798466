import AzureAuth from 'react-native-azure-auth';
import { store, resetReduxPersist } from 'src/redux/store';
import { logoutAction, saveAuthInfoAction } from 'src/redux/auth/authActions';
import { apiCache } from 'src/hooks/reactQuery';
import { isWeb } from './index';

const prodConfig = {
  clientId: '3b11c8e2-79bf-49b9-b4ea-1427df2aa295',
  tenant: 'permira.com',
};
const devConfig = {
  clientId: '3fb28853-6526-431e-939e-04828c304ab4',
  tenant: 'rfalab.com',
};

// const azureAuthInstance = new AzureAuth(__DEV__ ? devConfig : prodConfig);
const azureAuthInstance = new AzureAuth(prodConfig);

export default azureAuthInstance;

export const login = async (userId) => {
  try {
    // Try to get cached token or refresh an expired ones
    let tokens;
    if (userId) {
      tokens = await azureAuthInstance.auth.acquireTokenSilent({ scope: 'Mail.Read', userId });
    }
    if (!tokens) {
      // No cached tokens or the requested scope defines new not yet consented permissions
      // Open a window for user interaction
      tokens = await azureAuthInstance.webAuth.authorize({ scope: 'Mail.Read' });
    }
    // eslint-disable-next-line no-unused-expressions
    store?.dispatch(saveAuthInfoAction(tokens));
  } catch (error) {
    console.log(error);
  }
};

// const isAuthInfoValid = (authInfo) => ['accessToken', 'userId'].every((el) => !!authInfo?.[el]);

// export const storePassedAuthInfoWeb = (authInfo) => {
//   if (isWeb() && isAuthInfoValid(authInfo)) {
//     store?.dispatch(saveAuthInfoAction(authInfo));
//   }
// };

export const logout = () => {
  azureAuthInstance.auth.clearPersistenCache();
  apiCache.clear();
  resetReduxPersist();
  if (isWeb()) {
    window?.permira?.logout();
  }
  // eslint-disable-next-line no-unused-expressions
  store?.dispatch(logoutAction());
};

export const getToken = async () => {
  const username = store?.getState().auth?.username;
  // const token = store?.getState().auth?.accessToken;
  let response;
  if (username) {
    if (isWeb()) {
      response = await window?.permira?.acquireTokenSilent(username);
    } else {
      response = await azureAuthInstance.auth.acquireTokenSilent({ scope: 'Mail.Read', userId: username });
    }
  }
  console.log('GET_TOKEN', username, response);
  return response?.accessToken;
  // return token;
};

// export const getAccessToken = () => azureAuthInstance.auth.cache.getAccessToken();
