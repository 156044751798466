import React from 'react';
import {
  ShowerIcon, AttendanceIcon, BikeIcon, FoodIcon, UsaIcon, EuropeIcon, AsiaIcon,
} from 'src/assets/icons';
import { colors } from './theme';

export const bookingTypes = {
  BIKE: 'Bike',
  LUNCH: 'Lunch',
  SHOWER: 'Shower',
  ATTENDANCE: 'Attendance',
};

export const bookingTypeColors = {
  [bookingTypes.BIKE]: colors.blue,
  [bookingTypes.LUNCH]: colors.brown3,
  [bookingTypes.SHOWER]: colors.blue2,
  [bookingTypes.ATTENDANCE]: colors.green,
};

export const bookingTypeIcons = {
  [bookingTypes.BIKE]: <BikeIcon />,
  [bookingTypes.LUNCH]: <FoodIcon />,
  [bookingTypes.SHOWER]: <ShowerIcon />,
  [bookingTypes.ATTENDANCE]: <AttendanceIcon />,
};

export const bookingGifSources = {
  [bookingTypes.BIKE]: require('src/assets/Bike.gif'),
  [bookingTypes.LUNCH]: require('src/assets/Lunch.gif'),
  [bookingTypes.SHOWER]: require('src/assets/Shower.gif'),
  [bookingTypes.ATTENDANCE]: require('src/assets/Attendance.gif'),
};

export const locationIcons = {
  US: <UsaIcon style={{ position: 'absolute', top: 20 }} />,
  EUROPE: <EuropeIcon style={{ position: 'absolute', top: 5 }} />,
  ASIA: <AsiaIcon style={{ position: 'absolute', top: 5 }} />,
};
