export const colors = {
  darkblue: '#100f27',
  white: '#FFFFFF',
  white2: '#EFEEF1',
  brown: '#F68C1F',
  brown2: '#F78C20',
  brown3: '#FF6E40',
  grey: '#8E8793',
  grey2: '#403F52',
  grey3: '#636363',
  green: '#67AB5B',
  grey4: '#4A495C',
  grey5: '#766F7D',
  grey6: '#5E5C78',
  grey7: '#8E8894',
  blue: '#6B9DD9',
  blue2: '#A9CFF5',
};

export const theme = {
  fonts: {
    regular: 'Calibri',
    bold: 'Calibri-Bold',
  },
  primaryColor: colors.darkblue,
  secondaryColor: colors.brown,
  background: colors.darkblue,
  backgroundSecondary: colors.grey2,
  primaryText: colors.white2,
  secondaryText: colors.grey7,
  tabActive: colors.brown,
  tabInactive: colors.grey,
  timePicker: {
    active: colors.brown2,
    inactive: colors.grey6,
  },
  input: {
    background: colors.grey2,
    placeholder: colors.grey5,
  },
  calendar: {
    disabledDayText: colors.grey5,
    dayText: colors.white,
    todayText: colors.white,
    selectedBackground: colors.brown2,
  },
};
