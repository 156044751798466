import {
  View, FlatList, ScrollView, TouchableOpacity, Modal,
} from 'react-native';
import FlexView from './FlexView';
import Text from './Text';
import Button from './Button';
import Title from './Title';
import Input from './Input';
import Spinner from './Spinner';
import Card from './Card';

const Common = {
  FlexView,
  Button,
  Text,
  View,
  Card,
  Title,
  Input,
  Modal,
  Spinner,
  TouchableOpacity,
  FlatList,
  ScrollView,
};

export default Common;
