import axios from 'axios';
import { logout, getToken } from 'src/utils/azureAuth';
import { getLocation } from 'src/hooks/useLocation';

const isStaging = process.env.REACT_APP_ENV === 'uat';
// export const BASE_URL = __DEV__ ? 'https://permirahome-api.azurewebsites.net/api/' : 'https://permirabooking.azurewebsites.net/api/';
export const BASE_URL = 'https://permirabooking.azurewebsites.net/api/';
// export const BASE_URL = 'https://permirabookinguat.azurewebsites.net/api/';
// export const BASE_URL = 'https://permirabookinguat.azurewebsites.net/api/';

export const ADMIN_URL = 'https://apps.powerapps.com/play/43c4183d-2be5-4c87-b7b2-9fb00f8434a0?tenantId=9b1a952d-a735-47ee-84a8-0b3a8650b8cc';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.headers['x-AuthKey']) {
      const token = await getToken();
      // console.log('axios token:', token);
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers['x-AuthKey'] = token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('error:', error);
    if (error?.response?.status === 401) {
      logout();
    }
    return Promise.reject(error);
  },
);

export const permiraAPI = {
  getMyBookings: (_, { location }) => axiosInstance.get(`/v2/booking/${location}/me`).then(({ data }) => data),
  createBooking: (payload) => axiosInstance.post('/booking', payload),
  createBookingMultipe: (payload) => axiosInstance.post('/booking/multiple', payload),
  getResourcesByType: (_, { bookingType, location }) => axiosInstance.get(`/v2/booking/${location}/${bookingType}/resources`).then(({ data }) => (Array.isArray(data) ? data?.[0] : data)),
  deleteBooking: ({ bookingType, bookingId }) => axiosInstance.delete(`/booking/${bookingType}/${bookingId}`),
  getLocations: () => axiosInstance.get('/v2/booking/locations')?.then(({ data }) => data),
  getBookingTypes: (_, { location }) => axiosInstance.get(`/v2/booking/${location}/bookingTypes`).then(({ data }) => data),
  getMySettings: () => axiosInstance.get('/v2/booking/usersettings').then(({ data }) => data),
  getQuestionnaire: (location) => (location ? axiosInstance.get(`/questionnaire/${location}`).then(({ data }) => data) : null),
  saveQuestionnaire: (payload, location) => (location ? axiosInstance.post(`/questionnaire/${location}`, payload) : null),
};
