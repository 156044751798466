import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { updateLocationAction } from 'src/redux/settings/settingsActions';
import { store } from 'src/redux/store';

export const useLocation = () => {
  const location = useSelector((state) => state.settings.location);
  const dispatch = useDispatch();
  const updateLocation = useCallback((newLocation) => {
    if (newLocation) {
      dispatch(updateLocationAction(newLocation));
    }
  }, [location]);
  return {
    location,
    updateLocation,
  };
};

export const getLocation = () => store?.getState().settings?.location;
