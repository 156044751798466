import { responsiveWidth } from 'src/utils';
import { StyleSheet } from 'react-native';
import { theme, colors } from 'src/constants/theme';
import { isDeskTopWeb } from 'src/utils/responsive';

const styles = StyleSheet.create({
  card: {
    width: isDeskTopWeb() ? 323 : responsiveWidth(55),
    marginRight: 24,
    height: isDeskTopWeb() ? 400 : 336,
  },
  cardItem: {
    padding: 16,
  },
  header: {
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    elevation: 3,
    // shadowRadius: 10,
    shadowOpacity: 0.5,
    backgroundColor: theme.backgroundSecondary,
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
  },
  divider: {
    borderBottomColor: colors.grey4,
    borderBottomWidth: 1,
  },
});

export default styles;
