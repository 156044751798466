import React, { useState, useEffect } from 'react';
import RadioForm, { RadioButton, RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button';
import { theme } from 'src/constants/theme';
import Common from '../common';

const radioProps = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
const YesNo = ({ onChangeAnswer, onOpenNextCard }) => {
  const [answer, setAnswer] = useState(null);
  useEffect(() => {
    onChangeAnswer({
      booleanValue: !!answer,
    });
  }, [answer]);
  return (
    <Common.View style={{ marginTop: 20 }}>
      <RadioForm
        formHorizontal
        animation
      >
        {
    radioProps.map((obj, i) => (
      <RadioButton
        style={{
          marginRight: 30,
        }}
        labelHorizontal
        key={i}
      >
        <RadioButtonInput
          obj={obj}
          index={i}
          isSelected={answer === obj.value}
          borderWidth={1}
          buttonInnerColor={theme.secondaryColor}
          buttonOuterColor="#6E6C8B"
          buttonSize={15}
          onPress={() => setAnswer(obj?.value)}
          buttonOuterSize={24}
          buttonStyle={{ borderWidth: 2 }}
        />
        <RadioButtonLabel
          obj={obj}
          index={i}
          labelHorizontal
          labelStyle={{ fontSize: 16, color: '#fff' }}
          labelWrapStyle={{}}
          onPress={() => setAnswer(obj?.value)}
        />
      </RadioButton>
    ))
  }
      </RadioForm>
    </Common.View>
  );
};

export default YesNo;
