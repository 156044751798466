import React from 'react';
import Common from 'src/components/common';
import { theme } from 'src/constants/theme';

const style = {
  textAlign: 'center',
  marginVertical: 10,
};

const EmptyMessage = ({ message }) => (
  <Common.FlexView>
    <Common.Text color={theme.secondaryText} size={16} style={style}>
      {message}
    </Common.Text>
  </Common.FlexView>
);
EmptyMessage.defaultProps = {
  message: 'No data',
};

export default EmptyMessage;
