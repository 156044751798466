import actionTypes from 'src/constants/actionTypes';

const defaultState = {
  location: null,
};

const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOCATION:
      return { location: action.payload };
    default:
      return state;
  }
};

export default settingsReducer;
