import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

const defaultStyle = {
  marginBottom: 16,
  marginTop: 32,
};

const Title = ({ title, style }) => (
  <Text style={[defaultStyle, style && style]} size={32} bold>
    {title}
  </Text>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
