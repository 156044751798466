/* eslint-disable react/prop-types */
import * as React from 'react';
import { NavigationContainer, useLinking } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useSelector } from 'react-redux';
import Login from 'src/screens/Login';
import Bookings from 'src/screens/Bookings';
import screenNames from 'src/constants/screenNames';
import Settings from 'src/screens/Settings';
import Apploading from 'src/screens/Apploading';
import { theme } from 'src/constants/theme';
import Tabbar from 'src/components/Tabbar';
import useTimer from 'src/hooks/useTimer';
import { ADMIN_URL, permiraAPI } from 'src/constants/api';
import { LeftArrowIcon, Logo, LocationIcon } from 'src/assets/icons';
import SingleBooking from 'src/screens/SingleBooking';
import navigationService, { navigationRef } from 'src/utils/navigation';
import {
  TouchableOpacity, StyleSheet,
} from 'react-native';
import { login } from 'src/utils/azureAuth';
import BookingPreview from 'src/screens/BookingPreview';
import PickLocation from 'src/screens/PickLocation';
import { isWeb, responsiveWidth, openUrl } from 'src/utils';
import { useLocation } from 'src/hooks/useLocation';
import Common from 'src/components/common';
import Header from 'src/components/Header';

import { isDeskTopWeb } from 'src/utils/responsive';

const Stack = createStackNavigator();

const Tab = createBottomTabNavigator();

const tabStyles = StyleSheet.create({
  locationContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#F68C1F',
    padding: 5,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  text: {
    color: '#F68C1F',
    marginRight: 5,
  },
  adminContainer: {
    marginRight: 15,
  },
});

const HomeTabs = ({ navigation, route }) => {
  const { location } = useLocation();

  React.useLayoutEffect(() => {
    if (route?.name === screenNames.HOME_TABS) {
      navigation.setOptions({
        headerLeft: null,
        gestureEnabled: false,
        // headerTitle: () => (
        //   <Logo key="LOGO" height="31" width="250" style={{ marginBottom: 10 }} />
        // ),
        headerRight: () => (
          <Common.FlexView alignItems="center" flexDirection="row">
            {/* <Common.TouchableOpacity onPress={() => openUrl(ADMIN_URL)} style={tabStyles.adminContainer}>
              <Common.Text bold size={17}>Admin</Common.Text>
            </Common.TouchableOpacity> */}
            <Common.TouchableOpacity onPress={() => navigationService.navigate(screenNames.PICK_LOCATION)}>
              <Common.View style={tabStyles.locationContainer}>
                <Common.Text style={tabStyles.text}>{location}</Common.Text>
                <LocationIcon />
              </Common.View>
            </Common.TouchableOpacity>
          </Common.FlexView>
        ),
      });
    } else {
      navigation.setOptions({
        headerLeft: null,
        gestureEnabled: false,
        headerRight: null,
      });
    }
  }, [location]);
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarIcon: () => null,
        tabBarVisible: !isDeskTopWeb(),
      }}
      tabBar={(props) => <Tabbar {...props} />}
    >
      <Tab.Screen name={screenNames.BOOKINGS} component={Bookings} />
      <Tab.Screen name={screenNames.SETTINGS} component={Settings} />
    </Tab.Navigator>
  );
};

const StackNavWithHeader = ({ children, ...props }) => (
  <Stack.Navigator
    screenOptions={{
      headerLeft: (props) => {
        // console.log('props:', window.location.pathname);
        const isHomeScreen = window?.location?.pathname === '/';
        // console.log('isHomeScreen:', isHomeScreen);
        if (isHomeScreen) return null;
        return (
          <TouchableOpacity
            style={{
              width: 50,
              height: 40,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (typeof props.onPress === 'function') {
                props.onPress();
              } else {
                navigationService.navigate(screenNames.HOME_TABS);
              }
            }}
          >
            <LeftArrowIcon />
          </TouchableOpacity>
        );
      },
      headerBackTitleVisible: false,

      cardStyle: {
        backgroundColor: theme.background,
        flex: 1,
        width: isDeskTopWeb() ? 900 : '100%',
        alignSelf: isDeskTopWeb() ? 'center' : 'auto',
      },
      headerStyle: {
        borderBottomColor: theme.secondaryColor,
        borderBottomWidth: 1,
        backgroundColor: theme.background,
      },
      headerTitleAlign: 'center',
      headerTitle: () => (
        <img src={require('src/assets/logo.png')} alt="logo" height="31" style={{ marginBottom: 10 }} />
      ),
      // header: (props) => <Header {...props} />,
      // animationEnabled: true,
    }}

    headerMode="screen"
    {...props}
  >
    {children}
  </Stack.Navigator>
);

const privateRoutes = [
  {
    name: screenNames.HOME_TABS,
    component: HomeTabs,
  },
  {
    name: screenNames.SINGLE_BOOKING,
    component: SingleBooking,
  },
  {
    name: screenNames.BOOKING_PREVIEW,
    component: BookingPreview,
  },
  {
    name: screenNames.PICK_LOCATION,
    component: PickLocation,
  },
];

const config = {
  [screenNames.HOME_TABS]: {
    // path: '/',
    screens: {
      [screenNames.BOOKINGS]: {
        path: '',
      },
      [screenNames.SETTINGS]: 'settings',
    },
  },
  [screenNames.SINGLE_BOOKING]: 'create-booking',
  [screenNames.LOGIN]: 'login',
  [screenNames.PICK_LOCATION]: 'pick-location',
  [screenNames.BOOKING_PREVIEW]: 'booking',
};

export default function RootNavigation() {
  const authInfo = useSelector((store) => store.auth);
  const { location: cachedLocation, updateLocation } = useLocation();
  const [locationChecked, setLocationChecked] = React.useState(!!cachedLocation);
  const [location, setLocation] = React.useState(cachedLocation);
  const isCacheLoaded = useSelector((store) => store?._persist?.rehydrated);

  const { getInitialState } = useLinking(navigationRef, {
    config,
  });

  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    getInitialState()
      .catch(() => {})
      .then((state) => {
        if (state !== undefined) {
          setInitialState(state);
        }

        setIsReady(true);
      });
  }, [getInitialState]);

  const { isFinished } = useTimer(3);

  React.useEffect(() => {
    if (authInfo && !isWeb()) {
      login(authInfo.username);
    }
    if (isWeb()) {
      window?.closePreloader();
    }
  }, []);
  React.useEffect(() => {
    if (authInfo && !cachedLocation) {
      permiraAPI.getMySettings()
        .then((response) => {
          if (response?.settings) {
            updateLocation(response.settings?.location);
            setLocation(response.settings?.location);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLocationChecked(true);
        });
    }
    if (!authInfo) {
      setLocationChecked(false);
      setLocation(null);
    }
  }, [authInfo, cachedLocation]);

  if ((!locationChecked && !!authInfo) || !isCacheLoaded || !isFinished || !isReady) {
    return <Apploading withSpinner={(!locationChecked && !!authInfo && isFinished)} />;
  }

  return (
    <NavigationContainer
      theme={{
        colors: {
          background: theme.background,
        },
      }}
      initialState={initialState}
      ref={navigationRef}
    >
      {!authInfo ? (
        <Stack.Navigator
          headerMode="none"
          screenOptions={{
            animationTypeForReplace: 'pop',
          }}
          initialRouteName={screenNames.LOGIN}
        >
          <Stack.Screen name={screenNames.LOGIN} component={Login} />
        </Stack.Navigator>
      ) : (
        <StackNavWithHeader initialRouteName={
          location ? screenNames.HOME_TABS : screenNames.PICK_LOCATION
        }
        >
          {privateRoutes.map((route) => (
            <Stack.Screen key={route.name} name={route.name} component={route.component} />
          ))}

        </StackNavWithHeader>
      )}
    </NavigationContainer>
  );
}
