import React, { useState, useRef, useMemo } from 'react';
import Common from 'src/components/common';
import { StyleSheet, ActivityIndicator } from 'react-native';

import { theme } from 'src/constants/theme';
import { isDeskTopWeb } from 'src/utils/responsive';
import { LeftArrowIcon, RightArrowIcon } from 'src/assets/icons';
import BookingsCard from '../BookingsCard';

const DESKTOP_SCROLL_OFFSET = 323 * 2 + 48; // card_width * 2 + HORIZONTAL_PADDING * 2

const styles = StyleSheet.create({
  list: {
    height: isDeskTopWeb() ? 400 : 336,

  },
  container: {
    flexGrow: 1,
    paddingLeft: 24,
  },
});

const BookingsList = ({ data, isLoading }) => {
  const [currentXOffset, setCurrentXOffset] = useState(0);
  const [reachedEnd, setReachedEnd] = useState(false);
  const flatListRef = useRef();

  const filteredData = useMemo(() => (data || []).map((curr) => {
    const newData = {};
    curr.collection.forEach((el) => {
      if (!newData[el.bookingType]) {
        newData[el.bookingType] = {
          ...el,
          ...(el.bookingType === 'Shower' && { slots: [el.slot] }),
        };
        delete newData[el.bookingType].slot;
      } else if (el.bookingType === 'Shower') {
        newData[el.bookingType].slots.push(el.slot);
      }
    });
    return {
      ...curr,
      collection: Object.values(newData),
    };
  }), [data]);

  const onScroll = ({
    nativeEvent: { layoutMeasurement, contentSize, contentOffset },
  }) => {
    const newXOffset = contentOffset.x;
    const paddingToRight = 15;
    const isReachedEnd = layoutMeasurement.width + contentOffset.x >= contentSize.width - paddingToRight;
    if (isReachedEnd) {
      setReachedEnd(true);
    } else if (isReachedEnd !== reachedEnd) {
      setReachedEnd(false);
    }
    setCurrentXOffset(newXOffset);
  };
  const onRightArrow = () => {
    const _currentXOffset = currentXOffset + DESKTOP_SCROLL_OFFSET;
    // eslint-disable-next-line no-unused-expressions
    flatListRef?.current?.scrollToOffset({ offset: _currentXOffset });
  };

  const onLeftArrow = () => {
    // eslint-disable-next-line no-unused-expressions
    flatListRef?.current?.scrollToOffset({ offset: currentXOffset - DESKTOP_SCROLL_OFFSET });
  };
  return (
    <>
      <Common.FlatList
        data={filteredData}
        horizontal
        style={styles.list}
        ref={flatListRef}
        onScroll={onScroll}
        ListEmptyComponent={(
          <Common.FlexView justifyContent="center" alignItems="center">
            {(isLoading && !data) ? <ActivityIndicator size="large" color="#fff" /> : <Common.Text size={18} color={theme.secondaryText}>You have no bookings yet</Common.Text>}
          </Common.FlexView>
    )}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.container}
        renderItem={({ item }) => <BookingsCard item={item} />}
        keyExtractor={(item) => item?.bookingDate}
      />
      { isDeskTopWeb() && data && (
      <>
        {currentXOffset > 0 && (
        <Common.TouchableOpacity
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: -100,
            justifyContent: 'center',
          }}
          onPress={onLeftArrow}
        >
          <LeftArrowIcon height={50} width={30} />
        </Common.TouchableOpacity>
        )}
        {!reachedEnd && (
        <Common.TouchableOpacity
          style={{
            position: 'absolute',
            top: 0,
            bottom: -100,
            justifyContent: 'center',
            right: 0,
          }}
          onPress={onRightArrow}
        >
          <RightArrowIcon height={50} width={30} />
        </Common.TouchableOpacity>
        )}
      </>
      )}
    </>
  );
};

export default BookingsList;
