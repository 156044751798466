import React from 'react';
import Common from 'src/components/common';
import { theme } from 'src/constants/theme';

const style = {
  textAlign: 'center',
  marginVertical: 10,
};

const PickerEmptyMessage = ({ message }) => (
  <Common.Text color={theme.secondaryText} size={16} style={style}>
    {message}
  </Common.Text>
);
PickerEmptyMessage.defaultProps = {
  message: 'No data',
};

export default PickerEmptyMessage;
