import actionTypes from 'src/constants/actionTypes';

const defaultState = null;

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_AUTH_INFO:
      return action.payload;
    default:
      return state;
  }
};

export default authReducer;
