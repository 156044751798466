import { AppRegistry } from 'react-native'
import Calibri  from '../../assets/fonts/Calibri.ttf';
import CalibriBold  from '../../assets/fonts/Calibri-Bold.ttf';
import CalibriLight  from '../../assets/fonts/Calibri-Light.ttf';
import MaterialCommunityIcons  from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import './index.css';
import App from './App2'
import Modal from 'modal-react-native-web';
import * as serviceWorker from './serviceWorker';
import SuccessAlert from 'src/components/SuccessAlert';


Modal.setAppElement('#app_modal');

const iconFontStyles = `
@font-face {
  src: url(${Calibri});
  font-family: Calibri;
  font-display: swap;
}
@font-face {
  src: url(${CalibriBold});
  font-family: Calibri-Bold;
  font-display: swap;
}
@font-face {
  src: url(${CalibriLight});
  font-family: Calibri-Light;
  font-display: swap;
}
@font-face {
  src: url(${MaterialCommunityIcons});
  font-family: MaterialCommunityIcons;
}
`;


const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

AppRegistry.registerComponent('PermiraGO', () => App)
AppRegistry.runApplication('PermiraGO', {
  rootTag: document.getElementById('root'),
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: async registration => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      SuccessAlert.show({
        title: "New version available",
        message: "To use the application, you need to upgrade it to the latest version.",
        disableOverlayClose: true,
        button: {
          visible: true,
          handler: async (closePopup) => {
            await registration.unregister();
            // Makes Workbox call skipWaiting()
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // Once the service worker is unregistered, we can reload the page to let
            // the browser download a fresh copy of our app (invalidating the cache)
            if(closePopup){
              closePopup()
            }
            window.location.reload();
          },
          title: "Update now"
        }
      });
    }
  },
});
