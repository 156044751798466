import React, { useEffect } from 'react';
import {
  StyleSheet,
  View,
  Image,
} from 'react-native';

import SplashScreen from 'react-native-splash-screen';
import useTimer from 'src/hooks/useTimer';
import { isWeb } from 'src/utils';
import { Logo } from 'src/assets/icons';
import Common from 'src/components/common';
import { isDeskTopWeb } from 'src/utils/responsive';

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    backgroundColor: '#100f27',
    alignItems: 'center',
    paddingTop: isDeskTopWeb() ? '40%' : '60%',
  },
});

const AppLoading = ({ withSpinner }) => {
  const { isFinished } = useTimer(3);
  useEffect(() => {
    if (!isWeb()) {
      SplashScreen.hide();
    }
  }, []);
  return (
    <View style={styles.wrapper}>
      { (isFinished || withSpinner) ? (
        <>
          <Logo height="75" />
          {withSpinner && <Common.Spinner />}
        </>
      ) : (
        <Image style={{ height: 80 }} source={require('src/assets/logo.gif')} resizeMode="contain" />
      )}
    </View>
  );
};

AppLoading.defaultProps = {
  withSpinner: false,
};

export default React.memo(AppLoading);
