/* eslint-disable no-nested-ternary */
import { isIphoneX } from 'react-native-iphone-x-helper';
import {
  Platform, StatusBar, Dimensions,
} from 'react-native';

const { height, width } = Dimensions.get('window');
const standardLength = width > height ? width : height;

const deviceHeight = isIphoneX()
  ? standardLength - 78 // iPhone X style SafeAreaView size in portrait
  : Platform.OS === 'android'
    ? standardLength - StatusBar.currentHeight
    : standardLength;

/*
/* responsiveHeight is calculated with device height and returns
/* value in percentage
*/
export function responsiveHeight(percent) {
  const heightPercent = (percent * deviceHeight) / 100;
  return Math.round(heightPercent);
}

export function responsiveWidth(percent) {
  const widthPercent = (percent * width) / 100;
  return Math.round(widthPercent);
}

// guideline height for standard 5" device screen is 680
/*
/* responsivePixel is calculated with device height and returns
/* value in pixel
*/
export function responsivePixel(fontSize, standardScreenHeight = 640) {
  const calculatedPixel = (fontSize * deviceHeight) / standardScreenHeight;
  return Math.round(calculatedPixel);
}

export function isMobileWeb() {
  return Platform.OS === 'web' && Dimensions.get('window').width <= 600;
}

export function isDeskTopWeb() {
  return !isMobileWeb();
}
