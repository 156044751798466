/* eslint-disable*/
import React from 'react'
import App from 'src/App'
import { useEffect } from 'react'

import { useCallback } from 'react'
import { store } from 'src/redux/store'
import { saveAuthInfoAction } from 'src/redux/auth/authActions'


const Home = ({account}) => {
  const getAuthAccount = useCallback(async() =>{
      if(account) {
        // eslint-disable-next-line no-unused-expressions
        store?.dispatch(saveAuthInfoAction(account));
      }
  }, [account])
  useEffect(() => {
    getAuthAccount();
  }, [])
  console.log('LEFT WEB')
  return (
    <App />
  )
}

export default React.memo(Home)
