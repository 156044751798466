import { MsalAuthProvider, LoginType } from 'react-aad-msal';
 
// Msal Configurations
const config = {
  auth: {
    clientId: '3b11c8e2-79bf-49b9-b4ea-1427df2aa295',
    // authority: 'https://login.microsoftonline.com/rfalab.com',
    authority: 'https://login.microsoftonline.com/permira.com',
    // clientId: '3fb28853-6526-431e-939e-04828c304ab4',
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: window.location.origin,
    // redirectUri: 'http://localhost:3000'
  },
  cache: {
    // cacheLocation: "sessionStorage",
    // storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
export const authenticationParameters = {
  scopes: [
    'mail.read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)