/* eslint-disable react/prop-types */
import React, { useLayoutEffect } from 'react';
import Common from 'src/components/common';
import { Image, StyleSheet, Alert } from 'react-native';
import { theme } from 'src/constants/theme';
import { bookingGifSources } from 'src/constants/bookings';
import navigationService from 'src/utils/navigation';
import {
  isBiking, isShower, isAttendance, isLunch, formatDateToText,
} from 'src/utils';
import screenNames from 'src/constants/screenNames';
import SuccessAlert from 'src/components/SuccessAlert';
import { useDeleteBooking } from 'src/hooks/api';
import { isDeskTopWeb } from 'src/utils/responsive';

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 24,
    // backgroundColor: '#151125',
  },
  gif: {
    height: 190, width: '100%',
  },
  meta: {
    marginTop: 50,
    marginBottom: 30,
    borderRadius: 10,
    paddingVertical: 16,
    paddingHorizontal: 21,
    backgroundColor: theme.backgroundSecondary,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#4A495C',
    marginVertical: 16,
  },
  title: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%',
    textAlign: 'center',
  },
});

const defaultGif = require('src/assets/Attendance.gif');

const BookingPreview = ({ navigation, route }) => {
  console.log('route:', route);
  const { data: dataStr, previousRoute } = route.params;
  const data = JSON.parse(dataStr);
  console.log('data:', data);
  const onDeleteBooking = useDeleteBooking();
  const isPreviousRouteSingleBooking = previousRoute === screenNames.SINGLE_BOOKING;
  const BookingType = Array.isArray(data) ? data[0].BookingType : data?.BookingType;
  const bookingInformation = data?.bookingInformation;
  useLayoutEffect(() => {
    navigation.setOptions({
      // headerLeft: null,
      gestureEnabled: false,
    });
  }, []);
  const onDelete = () => {
    try {
      if (bookingInformation?.id) {
        SuccessAlert.show(`Deleting ${BookingType} Booking`);
        onDeleteBooking({ bookingType: BookingType, bookingId: bookingInformation.id })
          .then(() => {
            SuccessAlert.show('Deleted Booking');
            setTimeout(() => {
              SuccessAlert.hide();
              navigationService.goBack();
            }, 500);
          });
      } else {
        Alert.alert('Deletion Failed');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const BookingDate = Array.isArray(data) ? data[0].BookingDate : data.BookingDate;
  return (
    <Common.ScrollView contentContainerStyle={[{ paddingBottom: 45, paddingHorizontal: 24 }, isDeskTopWeb() && {
      width: 450,
      alignSelf: 'center',
    }]}
    >
      <Common.Title style={styles.title} title={`${BookingType} Booking`} />
      <Image style={styles.gif} resizeMode="contain" source={bookingGifSources[BookingType] || defaultGif} />
      <Common.View style={styles.meta}>
        <Common.Text bold size={18}>{formatDateToText(BookingDate, 'MMMM d')}</Common.Text>
        {!isAttendance(BookingType) && <Common.View style={styles.divider} />}
        {isBiking(BookingType) && (
          <Common.Text
            size={16}
          >
            {bookingInformation?.Slot}
          </Common.Text>
        )}
        {(isShower(BookingType) && Array.isArray(data)) && <Common.Text size={16}>{`${data[0].bookingInformation?.Cubicle}  ${data.map((slot) => slot.bookingInformation.Slot).join(', ')}`}</Common.Text>}
        {(isShower(BookingType) && !Array.isArray(data)) && <Common.Text size={16}>{`${bookingInformation?.Cubicle}  ${bookingInformation?.slots?.join(', ')}`}</Common.Text>}

        {isLunch(BookingType) && (
          <Common.Text
            size={16}
          >
            {bookingInformation?.MealType}
            {' '}
            {bookingInformation?.Slot ? bookingInformation?.Slot : ''}
          </Common.Text>
        )}
      </Common.View>
      {/* {isPreviousRouteSingleBooking ? ( */}
      <>
        <Common.Button title="My Bookings" onPress={() => (!isPreviousRouteSingleBooking ? navigationService.goBack() : navigationService.navigate(screenNames.HOME_TABS))} />
        {!isPreviousRouteSingleBooking && <Common.Button title="Delete" type="outline" onPress={onDelete} />}
      </>
      {/* ) : (
          <Common.Button title="Delete" onPress={onDelete} />
        )} */}
    </Common.ScrollView>
  );
};

export default React.memo(BookingPreview);
