import React, {
  useState, useEffect, useMemo,
} from 'react';
import Common from 'src/components/common';
import Calendar from 'src/components/Calendar';

import SuccessAlert from 'src/components/SuccessAlert';
import {
  useGetResource, useCreateMultipleBooking, useCreateBooking, useGetMyBookings,
} from 'src/hooks/api';
import { isToday, isTomorrow } from 'date-fns';
import { Alert } from 'react-native';
import navigationService from 'src/utils/navigation';
import screenNames from 'src/constants/screenNames';
import {
  isShower, isLunch, isAttendance, isValidType, isBiking, formatDateToText, getProperDate, openUrl,
} from 'src/utils';
import { useLocation } from 'src/hooks/useLocation';
import { isDeskTopWeb } from 'src/utils/responsive';
import { WarningIcon } from 'src/assets/icons';
import { bookingTypes } from 'src/constants/bookings';
import BikeBooking from './components/BikeBooking';
import LunchBooking from './components/LunchBooking';
import Attendance from './components/Attendance';
import Shower from './components/Shower';

const checkIfBookingPossible = (type, typeResources) => {
  if (isValidType(type)) {
    return Array.isArray(typeResources?.availability) && !!typeResources.availability.length;
  }
  return false;
};

const isQuestionnaireAvailable = ({ date, location }) => {
  if (!date || !location) return false;
  const locations = {
    LON: isTomorrow(date) || isToday(date),
  };
  return !!locations[location];
};

const isValidBookingInformation = (state) => {
  const { bookingInformation, BookingType } = state;
  if (!bookingInformation) return false;
  if (isShower(BookingType)) {
    return Boolean(Array.isArray(bookingInformation?.Slots) && bookingInformation.Slots.length && bookingInformation?.Cubicle);
  }
  return true;
};

const defaultQuestionnaireState = {
  visible: false,
  callback: null,
  close: null,
};

const showDayPopupMessage = (dayData) => {
  SuccessAlert.show({
    // title: 'New version available',
    message: dayData?.bookingNote,
    messageType: 'html',
    disableOverlayClose: true,
    button: {
      visible: true,
      handler: async (closePopup) => {
        if (closePopup) {
          closePopup();
        }
      },
      title: 'Okay',
    },
  });
};
const SingleBooking = ({ route, navigation }) => {
  const [calendarMaxDate, setCalendarMaxDate] = useState(null);
  const [availableDates, setAvailableDates] = useState(null);
  const [bookingType, setBookingType] = useState(route?.params?.bookingType);
  const existingBookingInformation = route?.params?.bookingInformation;
  const [questionnaireState, setQuestionnaireState] = useState(defaultQuestionnaireState);
  const existingBookingDate = route?.params?.BookingDate;

  const isEditableMode = existingBookingDate && existingBookingInformation;
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(existingBookingDate);
  const {
    data: myBookings,
  } = useGetMyBookings();

  const { data: typeResources, isLoading: resourcesLoading, isFetching } = useGetResource(bookingType);

  const isLoading = !typeResources && resourcesLoading;

  const onCreateBooking = useCreateBooking();
  const onCreateMultipleBooking = useCreateMultipleBooking();
  const { location } = useLocation();

  const [state, setState] = useState({
    bookingInformation: existingBookingInformation || {},
    BookingType: bookingType,
    BookingDate: existingBookingDate || calendarCurrentDate,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, BookingType: bookingType, bookingInformation: existingBookingInformation || {} }));
  }, [bookingType]);

  const isResourcesAvailable = useMemo(() => checkIfBookingPossible(bookingType, typeResources), [typeResources, bookingType]);
  useEffect(() => {
    if (isResourcesAvailable) {
      if (typeResources?.availability && Array.isArray(typeResources.availability)) {
        if (isValidType(bookingType)) {
          const lastAvailable = typeResources.availability?.[typeResources.availability.length - 1];
          const firstAvailable = typeResources.availability?.[0];
          if (!availableDates && firstAvailable) {
            if (firstAvailable.bookingNote && firstAvailable.bookingNoteType) {
              showDayPopupMessage(firstAvailable);
            }
          }
          // eslint-disable-next-line no-return-assign
          const avDates = typeResources.availability?.reduce((total, currEl) => (total[formatDateToText(currEl.bookingDate)] = currEl, total), {});
          setAvailableDates(avDates);
          if (!avDates?.[formatDateToText(calendarCurrentDate)]) {
            setCalendarCurrentDate(firstAvailable?.bookingDate ? getProperDate(firstAvailable.bookingDate) : null);
          }
          setCalendarMaxDate(lastAvailable?.bookingDate ? getProperDate(lastAvailable.bookingDate) : null);
        }
      }
    }
  }, [typeResources]);

  const selectedDateData = availableDates?.[formatDateToText(state.BookingDate)];

  const isBookingPossible = (selectedDateData && isResourcesAvailable) && isValidBookingInformation(state) && selectedDateData?.bookingNoteType !== 'block';

  useEffect(() => {
    if (!availableDates?.[formatDateToText(state.BookingDate)] && availableDates?.length) {
      setState({
        ...state,
        BookingDate: getProperDate(availableDates?.[0]?.bookingDate),
      });
    }
  }, [state]);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      BookingDate: calendarCurrentDate,
    }));
  }, [calendarCurrentDate]);

  const getDatePopupMessage = (date) => {
    const avDate = availableDates?.[date];
    if (avDate?.bookingNoteType && avDate?.bookingNote) {
      return avDate;
    }
    return null;
  };

  const onChangeDate = (date) => {
    const dayPopupMessage = getDatePopupMessage(date?.dateString);
    if (dayPopupMessage) {
      showDayPopupMessage(dayPopupMessage);
    }
    setState({
      ...state,
      BookingDate: new Date(date.year, date.month - 1, date.day),
    });
  };

  const onSelectAttendanceTime = (time) => {
    setState({
      ...state,
      BookingDate: time,
    });
  };

  const onUpdateBookingInformation = (bookingInformation, isAssign = false) => {
    const newState = {
      ...state,
    };
    if (isAssign) {
      newState.bookingInformation = bookingInformation;
    } else {
      newState.bookingInformation = {
        ...state.bookingInformation,
        ...bookingInformation,
      };
    }
    setState(newState);
  };

  const createBooking = (questionnaireId) => {
    let payload = {
      ...state,
      bookingInformation: {
        ...state.bookingInformation,
        QuestionnaireRef: questionnaireId || '',
      },
      BookingDate: formatDateToText(state?.BookingDate),
      BookingLocation: location,
    };
    const todaysBooking = myBookings.find((el) => el.bookingDate === payload.BookingDate);
    const hasAttendanceForDate = todaysBooking && todaysBooking?.collection?.find((el) => el.bookingType === bookingTypes.ATTENDANCE);
    if (!isAttendance(payload.BookingType) && !hasAttendanceForDate) {
      SuccessAlert.show({
        title: 'Notice!',
        message: 'Please create an attendance booking before making a shower/lunch/bike booking.',
        button: {
          visible: true,
          handler: async (closePopup) => {
            if (closePopup) {
              closePopup();
            }
            setBookingType(bookingTypes.ATTENDANCE);
          },
          title: 'Book Attendance',
        },
      });
      return;
    }

    let handler = onCreateBooking;

    if (isShower(payload.BookingType)) {
      payload = payload.bookingInformation.Slots.map((slot) => ({
        ...payload,
        bookingInformation: { Slot: slot, Cubicle: payload.bookingInformation.Cubicle },
      }));
      handler = onCreateMultipleBooking;
    }

    SuccessAlert.show(`Creating ${bookingType} Booking`);

    handler(payload).then((response) => {
      if (response?.status === 200) {
        SuccessAlert.show(`Created ${bookingType} Booking`);
        navigationService.navigate(screenNames.BOOKING_PREVIEW, {
          data: JSON.stringify(payload),
          previousRoute: screenNames.SINGLE_BOOKING,
        });
      } else {
        SuccessAlert.show('Booking Failed!', 'warning');
      }
    }).catch((error) => {
      SuccessAlert.show('Booking Failed!', 'warning');
    }).finally(() => {
      setTimeout(() => {
        SuccessAlert.hide();
      }, 2000);
    });
  };
  const onSubmit = () => {
    if (isEditableMode) {
      Alert.alert('Updating is not supported yet');
      return;
    }
    try {
      if (isAttendance(bookingType) && isQuestionnaireAvailable({ date: state?.BookingDate, location })) {
        setQuestionnaireState({
          visible: true,
          callback: (questionnaireId) => {
            setQuestionnaireState(defaultQuestionnaireState);
            createBooking(questionnaireId);
          },
          close: () => {
            setQuestionnaireState(defaultQuestionnaireState);
          },
        });
      } else {
        createBooking();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const currentDateData = availableDates?.[formatDateToText(state?.BookingDate)];

  return (
    <Common.ScrollView
      style={{ flexGrow: 1 }}
      contentContainerStyle={[{ paddingBottom: 50, flexGrow: 1 }, isDeskTopWeb() && {
        width: 450,
        alignSelf: 'center',
      }]}
    >
      <Common.View style={{ paddingHorizontal: 24 }}>
        <Common.Title title={`${bookingType} Booking`} />
        {(isLoading || isFetching) && (<Common.Spinner style={{ marginBottom: 20 }} />)}
        {typeResources?.message && (
          <Common.View style={{
            backgroundColor: '#403F52',
            borderRadius: 4,
            padding: 8,
            flexDirection: 'row',
            marginVertical: 20,
          }}
          >
            <Common.View style={{ flex: 1, marginRight: 10, color: '#FEFDFF' }}>
              <div dangerouslySetInnerHTML={{ __html: typeResources?.message }} />
            </Common.View>
            <WarningIcon height={27} width={27} />
          </Common.View>
        )}
        {typeResources?.menuDescription && (
          <Common.Text size={16} color="#FEFDFF" style={{ marginBottom: 10 }}>{typeResources.menuDescription}</Common.Text>
        )}
        {!isLoading && <Calendar isEditableMode={isEditableMode} availableDates={availableDates} onChangeDate={onChangeDate} selectedDate={state.BookingDate} maxDate={calendarMaxDate} currentDate={calendarCurrentDate} />}
        {typeResources?.menuNotes && (
          <Common.View style={{
            backgroundColor: '#403F52',
            borderRadius: 4,
            padding: 8,
            flexDirection: 'row',
            marginTop: 20,
          }}
          >
            <Common.View style={{ flex: 1, marginRight: 10 }}>
              <Common.Text size={14} color="#AFA8B7">{typeResources.menuNotes}</Common.Text>
            </Common.View>
            <WarningIcon height={27} width={27} />
          </Common.View>
        )}
      </Common.View>
      {!isLoading && (
        <Common.View>
          {isBiking(bookingType) && (
          <BikeBooking
            state={state}
            currentDateData={currentDateData}
            data={typeResources}
            onUpdateBookingInformation={onUpdateBookingInformation}
          />
          )}
          {isLunch(bookingType) && (
          <LunchBooking
            isBookingPossible={isBookingPossible}
            onUpdateBookingInformation={onUpdateBookingInformation}
            state={state}
            data={typeResources}
            currentDateData={currentDateData}
          />
          )}
          {isAttendance(bookingType) && <Attendance showTimeSlots={!!typeResources?.options?.allowTimeSlots} questionnaireState={questionnaireState} isEditableMode={isEditableMode} currentDate={calendarCurrentDate} onSelectAttendanceTime={onSelectAttendanceTime} data={typeResources} />}
          {isShower(bookingType) && (
          <Shower
            availableDates={availableDates}
            onUpdateBookingInformation={onUpdateBookingInformation}
            state={state}
            currentDateData={currentDateData}
            data={typeResources}
          />
          )}
          <Common.View style={{ paddingHorizontal: 24 }}>
            <Common.Button
              title="Create Booking"
              disabled={!isBookingPossible}
              style={{ marginTop: 24 }}
              onPress={onSubmit}
            />
          </Common.View>
        </Common.View>
      )}
    </Common.ScrollView>
  );
};

export default SingleBooking;
