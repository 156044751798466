import React from 'react';
import Common from 'src/components/common';
import { RightArrowIcon } from 'src/assets/icons';
import { isTomorrow, isSameDay } from 'date-fns';

import navigationService from 'src/utils/navigation';
import screenNames from 'src/constants/screenNames';
import {
  transformBookingToStateFormat, isAttendance, isShower, isBiking, isLunch, formatDateToText, getProperDate,
} from 'src/utils';
import styles from './style';

const getDateText = (itemDate) => {
  const todayDate = new Date();
  const parsedDate = getProperDate(itemDate);

  if (isSameDay(todayDate, parsedDate)) {
    return (
      <>
        Today,
        {'  '}
        <Common.Text size={18}>{formatDateToText(parsedDate, 'MMMM d')}</Common.Text>
      </>
    );
  } if (isTomorrow(parsedDate)) {
    return (
      <>
        Tomorrow,
        {'  '}
        <Common.Text size={18}>{formatDateToText(parsedDate, 'MMMM d')}</Common.Text>
      </>
    );
  }
  return formatDateToText(parsedDate, 'MMMM d');
};

const BookingsCard = ({ item }) => {
  const openBookingEditing = (booking) => {
    if (isAttendance(booking?.bookingType)) {
      navigationService.navigate(screenNames.BOOKING_PREVIEW, {
        data: JSON.stringify(transformBookingToStateFormat(booking?.attendanceBookingDate, booking)),
        previousRoute: screenNames.HOME_TABS,
      });
    } else {
      navigationService.navigate(screenNames.BOOKING_PREVIEW, {
        data: JSON.stringify(transformBookingToStateFormat(item?.bookingDate, booking)),
        previousRoute: screenNames.HOME_TABS,
      });
    }
  };
  return (
    <Common.Card style={styles.card}>
      <Common.View style={[styles.cardItem, styles.header]}>
        <Common.Text bold size={18}>
          {getDateText(item.bookingDate)}
        </Common.Text>
      </Common.View>
      <Common.ScrollView showsVerticalScrollIndicator={false} style={{ paddingHorizontal: 16 }}>
        {
       item?.collection.map((booking, idx) => (
         <Common.TouchableOpacity activeOpacity={0.5} key={booking.id} onPress={() => openBookingEditing(booking)}>
           <Common.FlexView
             flexDirection="row"
             justifyContent="space-between"
             alignItems="center"
             style={[{ paddingVertical: 16 }, idx !== item?.collection?.length - 1 && styles.divider]}
           >
             <Common.View>
               <Common.Text bold size={18}>{booking.bookingType}</Common.Text>
               {isShower(booking.bookingType) && <Common.Text>{`${booking.cubicle}  ${booking?.slots?.join(', ')}`}</Common.Text>}
               {isBiking(booking.bookingType) && booking?.slot && <Common.Text>{`${booking?.slot}`}</Common.Text>}
               {(isLunch(booking.bookingType) && booking.mealType) && <Common.Text>{`${booking?.mealType} ${booking?.slot ? booking?.slot : ''}`}</Common.Text>}
             </Common.View>
             <RightArrowIcon />
           </Common.FlexView>
         </Common.TouchableOpacity>
       ))
     }
      </Common.ScrollView>
    </Common.Card>
  );
};
export default React.memo(BookingsCard);
