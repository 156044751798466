import actionTypes from 'src/constants/actionTypes';

export const saveAuthInfoAction = (payload) => ({
  type: actionTypes.SAVE_AUTH_INFO,
  payload,
});

export const logoutAction = () => ({
  type: actionTypes.RESET_STORE,
});
