const actionTypes = {
  RESET_STORE: 'RESET_STORE',
  SAVE_AUTH_INFO: 'SAVE_AUTH_INFO',
  HIDE_SUCCESS_ALERT: 'HIDE_SUCCESS_ALERT',
  SHOW_SUCCESS_ALERT: 'SHOW_SUCCESS_ALERT',
  RESET_SUCCESS_ALERT: 'RESET_SUCCESS_ALERT',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
};

export default actionTypes;
