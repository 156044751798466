import { combineReducers } from 'redux';
import actionTypes from 'src/constants/actionTypes';
import authReducer from './auth/authReducer';
import successAlertReducer from './successAlert/successAlertReducer';
import settingsReducer from './settings/settingsReducer';

const appReducer = combineReducers({
  auth: authReducer,
  successAlert: successAlertReducer,
  settings: settingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.RESET_STORE) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
