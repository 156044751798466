import React, { useMemo, useEffect } from 'react';
import Common from 'src/components/common';
import { StyleSheet, Linking } from 'react-native';

import { MealMenuIcon1 } from 'src/assets/icons';

import { isWeb } from 'src/utils';
import Choice from 'src/components/Questionnaire/Choice';
import RadioChoice from 'src/components/RadioChoice';
import PickerEmptyMessage from './PickerEmptyMessage';

const styles = StyleSheet.create({
  customRadioLabel: {
    width: '100%', marginLeft: 10,
  },
  onlyNameRadioLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioPadding: {
    paddingHorizontal: 40,
  },
  title: {
    paddingHorizontal: 16 + 24,
    marginTop: 24,
  },
});

const LunchBooking = ({
  data, currentDateData, onUpdateBookingInformation, isBookingPossible,
}) => {
  const options = data?.options;
  const menuItems = useMemo(() => {
    if (currentDateData?.mealTypes) {
      return currentDateData?.mealTypes?.map(({ mealType, name, ...rest }) => ({
        value: mealType,
        label: name,
        ...rest,
      }));
    }
    return [];
  }, [currentDateData]);
  const slots = useMemo(() => {
    if (currentDateData?.slots) {
      return currentDateData?.slots?.map((slot) => ({
        value: slot,
        label: slot,
      }));
    }
    return [];
  }, [currentDateData]);

  const lunchMenuLocation = data?.lunchMenuLocation;
  const onOpenMenu = () => {
    const menuUrl = `${data?.lunchMenuLocation}`;
    if (isWeb()) {
      window?.open(menuUrl, '_blank');
    } else {
      Linking.openURL(menuUrl);
    }
  };
  const onUpdate = (key, value) => {
    onUpdateBookingInformation({
      [key]: value,
    });
  };
  useEffect(() => {
    onUpdateBookingInformation({
      MealType: currentDateData?.mealTypes?.[0]?.mealType,
      Slot: currentDateData?.slots?.[0],
    }, true);
  }, [currentDateData]);
  return (
    <Common.View>
      {options?.includes('MealType') && (
      <>
        <Common.Text bold size={18} style={styles.title}>Meal type</Common.Text>
        {menuItems?.length ? (
          <Common.View style={styles.radioPadding}>
            <RadioChoice
              choices={menuItems}
              onChange={(value) => onUpdate('MealType', value)}
              compareBy="label"
              renderLabel={(obj, { onPress }) => (
                <Common.TouchableOpacity
                  activeOpacity={0.5}
                  onPress={onPress}
                  style={[
                    styles.customRadioLabel,
                    (!obj?.allergen && !obj?.description && options?.includes('Menu'))
                    && styles.onlyNameRadioLabel,
                  ]}
                >
                  <Common.Text size={16} bold>{obj?.label}</Common.Text>
                  {options?.includes('Menu') && (
                    <>
                      {obj?.description && <Common.Text style={{ marginTop: 8 }} color="#CAC2D2" size={16}>{obj?.description}</Common.Text>}
                      {obj?.allergen && (
                        <Common.Text color="#1D82A6" bold size={14}>
                          Allergens:
                          {' '}
                          {obj?.allergen}
                        </Common.Text>
                      )}
                    </>
                  )}
                </Common.TouchableOpacity>
              )}
            />
          </Common.View>
        ) : <PickerEmptyMessage message="Not available" />}
      </>
      )}
      {options?.includes('Slot') && (
      <>
        <Common.Text bold size={18} style={styles.title}>Available slots</Common.Text>
        {slots?.length ? (
          <Common.View style={styles.radioPadding}>
            <RadioChoice
              choices={slots}
              onChange={(value) => onUpdate('Slot', value)}
            />
          </Common.View>
        ) : <PickerEmptyMessage message="Not available" />}
      </>
      )}
    </Common.View>
  );
};

export default LunchBooking;
