import React, { useMemo } from 'react';
import Common from 'src/components/common';
import { StyleSheet } from 'react-native';
import { bookingTypeColors, bookingTypeIcons, bookingTypes } from 'src/constants/bookings';
import navigationService from 'src/utils/navigation';
import screenNames from 'src/constants/screenNames';
import { useGetBookingTypes, usePrefetchResource } from 'src/hooks/api';
import EmptyMessage from 'src/components/EmptyMessage';
import { isDeskTopWeb } from 'src/utils/responsive';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    minHeight: 80,
    paddingHorizontal: 24,
  },
  circle: {
    width: 64,
    height: 64,
    borderRadius: 1000,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
  },
  circleWrapper: {
    alignItems: 'center',
    flex: isDeskTopWeb() ? 'unset' : 1,
    marginRight: isDeskTopWeb() ? 35 : 0,
  },
});

const NewBookingsList = ({ isLoading: isFetchingBookings }) => {
  const { data, isLoading } = useGetBookingTypes();

  const availableTypes = useMemo(() => {
    if (Array.isArray(data?.bookingTypes) && data.bookingTypes.length) {
      return data.bookingTypes.reduce((total, curr) => {
        if (bookingTypes?.[curr?.bookingType?.toUpperCase()]) {
          return [...total, curr];
        }
        return total;
      }, []);
    }
    return [];
  }, [data]);

  const onClickBookingType = (selectedType) => {
    try {
      navigationService.navigate(screenNames.SINGLE_BOOKING, selectedType);
    } catch (error) {
      console.log(error);
    }
  };

  const loading = isLoading || isFetchingBookings;
  return (
    <Common.FlexView
      style={styles.container}
    >
      {!availableTypes?.length && !loading && (
        <EmptyMessage message="You cannot book in this location" />
      )}
      {loading && <Common.Spinner />}
      {availableTypes && !loading
      && availableTypes.map((availableType) => (
        <Common.TouchableOpacity
          onPress={() => onClickBookingType(availableType)}
          style={styles.circleWrapper}
          key={availableType.bookingType}
        >
          <Common.View
            style={[styles.circle, { backgroundColor: bookingTypeColors[availableType?.bookingType] }]}
          >
            {bookingTypeIcons[availableType?.bookingType]}
          </Common.View>
          <Common.Text>{availableType?.bookingType}</Common.Text>
        </Common.TouchableOpacity>
      ))}
    </Common.FlexView>
  );
};

export default React.memo(NewBookingsList);
