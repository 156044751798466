/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { theme } from 'src/constants/theme';

import { store } from 'src/redux/store';
import { showSuccessAlertAction, hideSuccessAlertAction, resetSuccessAlertAction } from 'src/redux/successAlert/successAlertActions';
import { useSelector } from 'react-redux';
import { Image, StyleSheet } from 'react-native';
import { isDeskTopWeb } from 'src/utils/responsive';
import { isWeb } from 'src/utils';
import Common from './common';

const SuccessAlert = () => {
  const {
    message,
    messageType,
    type,
    visible,
    title,
    disableOverlayClose,
    button = {
      visible: false,
      title: 'OK',
      handler: (closePopup) => {
        closePopup();
      },
    },
  } = useSelector((state) => state.successAlert);

  const cancelHandle = () => {
    store?.dispatch(hideSuccessAlertAction());
    setTimeout(() => {
      store.dispatch(resetSuccessAlertAction());
    }, 300);
  };
  return (
    <Common.Modal
      visible={visible}
      animationType="fade"
      transparent
    >
      <Common.TouchableOpacity
        style={styles.backdrop}
        activeOpacity={1}
        onPress={disableOverlayClose ? () => {} : cancelHandle}
      >
        <Common.View
          style={[
            styles.card,
            {
              width: isDeskTopWeb() ? 300 : '85%',
            }]}
          activeOpacity={1}
        >
          <Common.View
            style={styles.messageWrapper}
            onStartShouldSetResponder={() => true}
          >
            {type === 'success' && <Image style={styles.successGif} source={require('src/assets/success.gif')} resizeMode="contain" />}
            {type === 'warning' && <Common.Text size={22} bold>Warning!</Common.Text>}
            {title && <Common.Text size={22} bold>{title}</Common.Text>}
            {message && (
            <Common.Text style={styles.message} size={18} color={theme.primaryText} bold>
              {(messageType === 'html' && isWeb()) ? (<div dangerouslySetInnerHTML={{ __html: message }} />) : message}
            </Common.Text>
            )}
          </Common.View>
          {(type === 'warning' || button?.visible) && (
            <Common.View style={styles.okBtn}>
              <Common.Button onPress={() => button?.handler(cancelHandle)} title={button?.title} />
            </Common.View>
          )}
        </Common.View>
      </Common.TouchableOpacity>
    </Common.Modal>
  );
};

SuccessAlert.show = (message, type = 'success') => {
  if (typeof message === 'object') {
    store?.dispatch(showSuccessAlertAction(message));
  } else {
    store?.dispatch(showSuccessAlertAction({ message, type }));
  }
};

SuccessAlert.hide = () => {
  store?.dispatch(hideSuccessAlertAction());
  setTimeout(() => {
    store.dispatch(resetSuccessAlertAction());
  }, 300);
};

export default SuccessAlert;

const styles = StyleSheet.create({
  card: {
    minHeight: 250,
    backgroundColor: '#353444',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageWrapper: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
  },
  message: { marginTop: 16, marginHorizontal: 10, textAlign: 'center' },
  backdrop: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(19,18,43,0.8)',
    margin: 0,
    padding: 0,
    height: '100%',
    zIndex: 99,
  },
  okBtn: { width: '100%', paddingHorizontal: 10, marginVertical: 10 },
  successGif: { height: 90, width: '100%', marginVertical: 20 },
});
